/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
<template>
    


 <div>
          <v-toolbar flat color="blue darken-4">
            <v-toolbar-title class="white--text">Client Administration</v-toolbar-title>
            <v-divider
              class="mx-2"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogMerchant" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">New Client</v-btn>
                
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Client Admin Page</span>
                </v-card-title>

                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.first_name" label="First Name"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.last_name" label="Last Name"></v-text-field>
                      </v-flex>
                       <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.username" label="User Name"></v-text-field>
                      </v-flex>
                       <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.cell" label="Cell Number"></v-text-field>
                      </v-flex>
                       <v-flex xs12 sm12 md12>
                        <v-text-field v-model="editedMerchant.email" label="Email Address"></v-text-field>
                      </v-flex>
                      
                      
                      
                      
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" flat @click="closeMerchant">Cancel</v-btn>
                  <v-btn color="blue darken-1" flat @click="saveMerchant">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-data-table
            :headers="headersMerchant"
            :items="MerchantData"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td>{{ props.item.first_name }} {{ props.item.last_name }}</td>
              <td class="text-xs-left">{{ props.item.username }}</td>
              <td class="text-xs-left">{{ props.item.email}}</td>
              <td class="text-xs-left">{{ props.item.cell }}</td>
              
              <td class="justify-left">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItemMerchant(props.item)"
                >
                  edit
                </v-icon>
                <v-icon
                  small
                  @click="deleteItemMerchant(props.item)"
                >
                  delete
                </v-icon>
              </td>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initializeMerchant">Reset</v-btn>
            </template>
          </v-data-table>
        </div>
    </template>
    <script>
    var Swal
    export default {
      beforeMount() {
        this.initializeMerchant()
      },
      watch: {
        dialogMerchant (val) {
          val || this.closeMerchant()
        },
      },
        data() {
            return {
                 nodeApi: 'https://serv.contitouch.co.zw/',
                // nodeApi : 'http://localhost:81/',
                MerchantData: [],
                dialogMerchant: false,
                 search: '',
                searchType: '',
                selectedCompanies: [],
                headersMerchant: [
                    {
                    text: 'Name',
                    align: 'left',
                    sortable: false,
                    value: 'name'
                    },
                    { text: 'User Name', value: 'username' },
                    { text: 'Cell No', value: 'cell' },
                    { text: 'Email', value: 'email' }
                ],
                editedIndexMerchant: -1,
                editedMerchant: {
                  id:'',
                  first_name: '',
                  last_name: '',
                  username: '',
                  cell: '',
                  email: ''
                },
                defaultMerchant: {
                  id:'',
                  first_name: '',
                  last_name: '',
                  username: '',
                  cell: '',
                  email: ''
                }
                
            }
        },
        methods: {
             //edit Merchant Data
      editItemMerchant (item) {
        this.editedIndexMerchant = this.MerchantData.indexOf(item)
        this.editedMerchant = Object.assign({}, item)
        this.dialogMerchant = true
      },
      //Delete Merchant Data
      deleteItemMerchant (item) {
        const index = this.MerchantData.indexOf(item)
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.MerchantData.splice(index, 1)
          }
        })
       
      },


              closeMerchant () {
                this.dialogMerchant = false
                setTimeout(() => {
                this.editedMerchant = Object.assign({}, this.defaultMerchant)
                this.editedIndexMerchant = -1
                }, 300)
            },

            saveMerchant () {
                if (this.editedIndexMerchant > -1) {
                Object.assign(this.MerchantData[this.editedIndexMerchant], this.editedMerchant)
                } else {
                this.MerchantData.push(this.editedMerchant)
                }
                this.closeMerchant()
            },
             initializeMerchant () {
                //get All Merchants from the Database through the getCompanies API
                this.axios.get(this.nodeApi+'getClients').then(data =>{
                this.MerchantData = data.data
                
                }).catch(err =>{
                Swal.fire({
                        type: "error",
                        title: "Can not load reports!!",
                        text: err
                    });
                })

                
            }
        },
    }
    </script>
    <style scoped>
    
    </style>
    
    