/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
<template>
    <v-layout row wrap justify-end>
       
        <v-flex xs12 sm6 md2>
            <v-text-field
                    append-icon="search"
                    label="Provider"
                    single-line
                    hide-details
                    @input="filterSearch"
            ></v-text-field>
        </v-flex>
        

        <v-flex xs12 sm6 md4>

            <v-menu
                    ref="show_start_date"
                    :close-on-content-click="false"
                    v-model="show_start_date"
                    :nudge-right="40"
                    :return-value.sync="start_date"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
            >
                <v-text-field
                        slot="activator"
                        v-model="start_date"
                        label="From"
                        prepend-icon="event"
                        readonly
                ></v-text-field>
                <v-date-picker
                        v-model="start_date"
                        @input="filterStartDate"
                ></v-date-picker>

            </v-menu>

        </v-flex>

        <v-flex xs12 sm6 md4>
            <v-menu
                    ref="show_end_date"
                    :close-on-content-click="false"
                    v-model="show_end_date"
                    :nudge-right="40"
                    :return-value.sync="end_date"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
            >
                <v-text-field
                        slot="activator"
                        v-model="end_date"
                        label="To"
                        prepend-icon="event"
                        readonly
                ></v-text-field>
                <v-date-picker
                        v-model="end_date"
                        @input="filterEndDate"
                ></v-date-picker>

            </v-menu>
        </v-flex>
        
        <v-flex xs12 sm6 md2 ma-0>
          <v-layout>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="refresh">
                  <v-icon v-on="on" color="gray">refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Report</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="exportPdf">
                  <v-icon v-on="on" color="red darken-2">picture_as_pdf</v-icon>
                </v-btn>
              </template>
              <span>Export to pdf</span>
            </v-tooltip>
            <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="exportExcel">
                <export-excel
                      class   = "btn btn-default"
                      :data   = "rowsFilter"
                      
                      worksheet = "Tran_Worksheet"
                      name    = "TransactionsReport.xls">
                  
                     <v-icon v-on="on" color="green darken-2">library_add</v-icon>
                  
                  </export-excel>
              </v-btn>
            </template>
            <span>Export to excel</span>
          </v-tooltip>
          </v-layout>
        </v-flex>
       

        <v-flex xs12>


            <v-data-table

                   
                    :headers="paymentHeaders"
                    :items="rows"
                    :pagination.sync="pagination"
                    
                    item-key="tran_id"
                    class="elevation-1"
                   
                    
                    :search="filters"
                    :custom-filter="customFilter"
            >
                
                <template v-slot:items="props">
                    <tr >
                        
                        
                       
                        <td class="text-xs-center">{{ props.item.tran_id }}</td>
                         <td class="text-xs-center">{{ props.item.tran_date_raw }}</td>
                        <td class="text-xs-center">{{ symbol + props.item.tran_amount_total }}</td>
                        <td class="text-xs-center">{{ symbol + props.item.tran_amount }}</td>
                        <td class="text-xs-center">{{ symbol + props.item.tran_charge }}</td>
                        
                        <td class="text-xs-left">{{ props.item.tran_account }}</td>
                        <td class="text-xs-left">{{ props.item.tran_merchantref }}</td>
                        <td class="text-xs-center">{{ props.item.tran_provider }}</td>
                        <td class="text-xs-left">{{ props.item.tran_gatewayref }}</td>
                        <td class="text-xs-left">{{ props.item.tran_providerresponse }}</td>
                        <td class="text-xs-left">{{ props.item.tran_description }}</td>
                        <td class="text-xs-center">{{ props.item.tran_provider }}</td>
                        <td class="text-xs-center">{{ props.item.tran_method }}</td>
                        <td class="text-xs-center">
                         
                             <v-chip label color="green" text-color="white" v-if="props.item.tran_status == 'paid'">
                              {{ props.item.tran_status }}
                            </v-chip>
                            
                            <v-chip label color="gray" text-color="black" v-if="props.item.tran_status == 'pending'">
                              {{ props.item.tran_status }}
                            </v-chip>

                            <v-chip label color="red" text-color="white" v-if="props.item.tran_status == 'declined'">
                              {{ props.item.tran_status }}
                            </v-chip>

                            <v-chip label color="blue darken-1" text-color="white" v-if="props.item.tran_status == 'approved'">
                              {{ props.item.tran_status }}
                            </v-chip>
                            <v-chip label color="blue" text-color="white" v-if="props.item.tran_status == 'queued'">
                              {{ props.item.tran_status }}
                            </v-chip>
                            <v-chip label color="yellow" text-color="white" v-if="props.item.tran_status == 'error'">
                              {{ props.item.tran_status }}
                            </v-chip>
                             <v-chip label color="purple darken-3" text-color="white" v-if="props.item.tran_status == 'confirmed'">
                              {{ props.item.tran_status }}
                            </v-chip>
                        

                        </td>
                    </tr>
                </template>
                <template v-slot:expand="props">
                  <v-card flat>
                    <v-card-text>{{props.item.tran_description}}</v-card-text>
                  </v-card>
                </template>
            </v-data-table>

        </v-flex>
        

    </v-layout>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import * as jsPDF from 'jspdf'
import * as autoTable from 'jspdf-autotable'
  export default {
     props: ['PropData','PropSymbol','ProCharge','PropCentre','PropCurrency'],
    data: () => ({
      expand: false,
      show_start_date: false,
      start_date: null,
      is_charged:null,
       nodeApi: 'https://serv.contitouch.co.zw/',
      show_end_date: false,
      rowsFilter: [],
      end_date: null,
      center_id :'',
      centreCurrency : '',
      filters: {
        search: '',
        added_by: '',
        start_date: null,
        end_date: null,
      },
      authors: ['charge', 'disburse'],
      pagination: {
        sortBy: 'date'
      },
      selected: [],
      symbol: '',

      //Excel data
      
        json_fields: {
               'tran_id': 'ID',
                'tran_date': 'Date',
                'tran_amount_total': 'Total Amount',
                'tran_amount': 'Net Amount',
                'tran_charge': 'Tran Cost',
                'tran_merchant': 'Merchant',
                'tran_account': 'Account No',
                'tran_provider': 'Provider',
                'tran_method': 'Method',
                'tran_status': 'Status',
        },
        json_data: [
            
        ],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        //End of excel data


     
      rows: []
    }),
    beforeMount() {
      
    },
    computed:{
      // eslint-disable-next-line vue/return-in-computed-property
      paymentHeaders(){
      let paymentHeaders = [
        
        {
          text: 'Tran Ref',
          value: 'tran_id'
        },
        {
          text: 'Tran Date',
          
          value: 'date'
        },
        {
          text: 'Total $',
          value: 'total'
        }
        ,
        {
          text: 'Net $',
          value: 'amount'
          
        },
        {
          text: 'Tran Cost',
          value: 'charge'
        },
        {
          text: 'Account No.',
          value: 'account',
         
        },
        {
          text: 'Merchant Ref',
          value: 'tran_merchantref'
        },
        {
          text: 'Provider',
          value: 'provider'
        },
        {
          text: 'Payment Ref',
          value: 'tran_gatewayref'
        },
        {
          text: 'Payment Response',
          value: 'tran_providerresponse'
        },
        {
          text: 'Description',
          value: 'tran_description'
        },
        {
          text: 'Provider',
          value: 'provider'
        },
        {
          text: 'Method',
          value: 'method'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ]
      if(this.is_charged == 0){
        let paymentHeaders = [
        
        {
          text: 'Tran Ref',
          value: 'tran_id'
        },
        {
          text: 'Tran Date',
          
          value: 'date'
        },
        {
          text: 'Total $',
          value: 'total'
        }
        ,
        {
          text: 'Net $',
          value: 'amount'
          
        },
        {
          text: 'Tran Cost',
          value: 'charge'
        },
        {
          text: 'Account No.',
          value: 'account',
         
        },
        {
          text: 'Merchant Ref',
          value: 'tran_merchantref'
        },
        {
          text: 'Provider',
          value: 'provider'
        },
        {
          text: 'Payment Ref',
          value: 'tran_gatewayref'
        },
        {
          text: 'Payment Response',
          value: 'tran_providerresponse'
        },
        {
          text: 'Description',
          value: 'tran_description'
        },
        {
          text: 'Provider',
          value: 'provider'
        },
        {
          text: 'Method',
          value: 'method'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ]
         return paymentHeaders
      }
      if(this.is_charged == 1){
        let paymentHeaders = [
        
        {
          text: 'Tran Ref',
          value: 'tran_id'
        },
        {
          text: 'Tran Date',
          
          value: 'date'
        },
        {
          text: 'Total $',
          value: 'total'
        }
        ,
        {
          text: 'Account No.',
          value: 'account',
         
        },
        {
          text: 'Merchant Ref',
          value: 'tran_merchantref'
        },
        {
          text: 'Provider',
          value: 'provider'
        },
        {
          text: 'Payment Ref',
          value: 'tran_gatewayref'
        },
        {
          text: 'Payment Response',
          value: 'tran_providerresponse'
        },
        {
          text: 'Description',
          value: 'tran_description'
        },
        {
          text: 'Provider',
          value: 'provider'
        },
        {
          text: 'Method',
          value: 'method'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ]
         return paymentHeaders
      }
     
      }
     },
    mounted() {
        this.rows = this.$props.PropData
        this.symbol = this.$props.PropSymbol
        this.is_charged = this.$props.ProCharge
        this.center_id = this.$props.PropCentre
        this.centreCurrency = this.$props.PropCurrency
        //console.log(this.rows)
    },
    methods: {
      refresh(){
        this.axios.post(this.nodeApi+'getPaymentMerchantTransactionsDisburse',{element1:this.center_id,element2:this.centreCurrency})
              .then(res =>{
                //console.log(res.data)
                  
                  this.rows = res.data
              
              }).catch(err =>{
                  this.progress = false
                        Swal.fire({
                        type: "error",
                        title: "Can not load reports!!",
                        text: err
                  });
              })
              
      },
      exportPdf(){
        // let pdfName = 'TransactionsReport'; 
        //   var doc = new jsPDF();
        //   doc.text(this.rows, 10, 10);
        //   doc.save(pdfName + '.pdf');
        var vm = this
        var columns = [
         {
          title: 'Tran Ref',
          dataKey: 'tran_id'
          },
           {
          title: 'Date',
         
          dataKey: 'tran_date'
        },
        {
          title: 'Total Amount',
          dataKey: 'tran_amount_total'
        },
        {
          title: 'Net Amount',
          dataKey: 'tran_amount'
        },
        {
          title: 'Tran Cost',
          dataKey: 'tran_charge'
        },
        {
          title: 'Merchant',
          dataKey: 'tran_merchant'
        },
        {
          title: 'Account No.',
          dataKey: 'tran_account'
        },
        {
          title: 'Provider',
          dataKey: 'tran_provider'
        },
        {
          title: 'Method',
          dataKey: 'tran_method'
        },
        {
          title: 'Status',
          dataKey: 'tran_status'
        }
        ]
        var doc = new jsPDF('l','pt')
        var totalPagesExp = "{total_pages_count_string}";

        doc.setTextColor(100);
        // doc.text('Contipay Merchant Report', 10, 20);
        //doc.autoTable(columns,vm.rows)
        doc.autoTable(
        columns, 
        vm.rowsFilter,
        {
        didDrawPage: function (data) {
            // Header
            doc.setFontSize(20);
            doc.setTextColor(40);
            doc.setFontStyle('normal');
            
            doc.text("Contipay Merchant Report", data.settings.margin.left + 15, 22);

            // Footer
            var str = "Page " + doc.internal.getNumberOfPages()
            // Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + "                                                                                                                                                                                                                                 @countitouch 2019";
            }
            doc.setFontSize(10);

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                  margin: {top: 30}
              });

              // Total page number plugin only available in jspdf v1.0+
              if (typeof doc.putTotalPages === 'function') {
                  doc.putTotalPages(totalPagesExp);
              }
           doc.save('TransactionReport.pdf')
        },
      exportExcel(){

      },
      customFilter(items, filters, filter, headers) {
        // Init the filter class.
        const cf = new this.$MultiFilters(items, filters, filter, headers);
        // Use regular function(),
        // arrow functions does not allow context binding.
        // Register the global standard filter.
        cf.registerFilter('search', function (searchWord, items) {
          if (searchWord.trim() === '') return items;
          return items.filter(item => {
            return item.tran_provider.toLowerCase().includes(searchWord.toLowerCase());
          }, searchWord);
        });
        // Use regular function(),
        // arrow functions does not allow context binding.
        // Register "added_by" filter.
        cf.registerFilter('added_by', function (added_by, items) {
          // If the filter has not been applied yet
          // just return all available items.
          if (added_by.trim() === '') return items;
          // Compare each item added_by and just return the matching ones.
          return items.filter(item => {
            return item.tran_method.toLowerCase() === added_by.toLowerCase();
          }, added_by);
        });
        // Use regular function(),
        // arrow functions does not allow context binding.
        // Register "start_date" filter.
        cf.registerFilter('start_date', function (start_date, items) {
          // If the filter has not been applied yet
          // just return all available items.
          if (start_date === null) return items;
          // Compare each item start_date and just return the matching ones.
          return items.filter(item => {
            return item.tran_date >= start_date;
          }, start_date);
        });
        // Use regular function(),
        // arrow functions does not allow context binding.
        // Register "end_date" filter.
        cf.registerFilter('end_date', function (end_date, items) {
          // If the filter has not been applied yet
          // just return all available items.
          if (end_date === null) return items;
          // Compare each item end_date and just return the matching ones.
          return items.filter(item => {
            return item.tran_date <= end_date;
          }, end_date);
        });
        // Its time to run all created filters.
        // Will be executed in the order thay were defined.
        this.rowsFilter = cf.runFilters()
        return cf.runFilters();
      },
      /**
       * Toggle selected items with the master checkbox.
       */
      toggleAll() {
        if (this.selected.length) {
          this.selected = [];
        } else {
          this.selected = this.rows.slice();
        }
      },
      /**
       * Column shorting.
       *
       * @param column
       */
      changeSort(column) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending;
        } else {
          this.pagination.sortBy = column;
          this.pagination.descending = false;
        }
      },
      /**
       * Handler when user input something at the "Filter" text field.
       */
      filterSearch(val) {
        this.filters = this.$MultiFilters.updateFilters(this.filters, {search: val});
      },
      /**
       * Handler when user  select some author at the "Author" select.
       */
      filterAuthor(val) {
        this.filters = this.$MultiFilters.updateFilters(this.filters, {added_by: val});
      },
      /**
       * Handler when select a date on "From" date picker.
       */
      filterStartDate(val) {
        // Close the date picker.
        this.$refs.show_start_date.save(val);
        //Convert the value to a timestamp before saving it.
        //const timestamp = new Date(val).getTime();
        this.filters = this.$MultiFilters.updateFilters(this.filters, {start_date: val});
      },
      /**
       * Handler when select a date on "To" date picker.
       */
      filterEndDate(val) {
        // Close the date picker.
        this.$refs.show_end_date.save(val);
        //Convert the value to a timestamp before saving it.
        //const timestamp = new Date(val).getTime();
        this.filters = this.$MultiFilters.updateFilters(this.filters, {end_date: val});
      },
    },
    filters: {
      /**
       * Format a timestamp into a d/m/yyy (because spanish format).
       *
       * @param value
       * @returns {string}
       */
      formatDate: function (value) {
        if (!value) return '';
        return new Date(value).toLocaleDateString("es-ES");
      }
    }
  };
</script>