<template>
    


 <div>
          <v-toolbar flat color="blue darken-4">
            <v-toolbar-title class="white--text">Merchant Centres Administration</v-toolbar-title>
            <v-divider
              class="mx-2"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogMerchant" max-width="500px">
              <template v-slot:activator="{ }">
               
              </template>
              <v-card>
                <v-card-title class="blue">
                  <span class="headline">Centre Admin Page</span>
                </v-card-title>

                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.centre_name" label="Centre Name"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.company_name" label="Company Name"></v-text-field>
                      </v-flex>
                       
                       <v-flex xs12 sm6 md12>
                        <v-text-field v-model="editedMerchant.logo" label="Centre Logo"></v-text-field>
                      </v-flex>
                       
                      
                      
                      
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" flat @click="closeMerchant">Cancel</v-btn>
                  <v-btn color="blue darken-1" flat @click="saveMerchant">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>

          <v-layout rows wrap>
          <v-flex lg8 sm12 xs12>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="headersMerchant"
            :items="MerchantData"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td>{{ props.item.id }}</td>
              <td @click="loadImage(props.item.logo)">{{ props.item.centre_name }}</td>
              <td @click="loadImage(props.item.logo)" class="text-xs-left">{{ props.item.company_name }}</td>
              
              <!-- <td class="text-xs-left">{{ props.item.logo }}</td> -->
             
              <td class="justify-left">
                              
            <v-layout row>
            <v-menu>
              <template v-slot:activator="{ on: menuEdit }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltipEdit }">
                <v-icon
                  small  
                    class="mr-2"                            
                   v-on="{ ...tooltipEdit, ...menuEdit }"
                >
                  edit
                </v-icon>
                </template>
                    <span>Edit Centre</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-tile
                    v-for="(item, index) in menuitemsEdit"
                    :key="index"
                    @click="selectedMenuItemEdit(props.item.id,item.title)"
                  >
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
                          
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                          <v-icon
                            small
                            class="mr-2"
                            v-on="on"
                            @click="deleteItemMerchant(props.item)"
                          >
                            delete
                          </v-icon>
                          </template>
                        <span>Delete Centre</span>
                  </v-tooltip>

                <v-menu>
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  small                                    
                   v-on="{ ...tooltip, ...menu }"
                >
                  account_balance
                </v-icon>
                </template>
                    <span>Centre Add-Ons</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-tile
                    v-for="(item, index) in menuitems"
                    :key="index"
                    @click="selectedMenuItem(props.item.id,item.title)"
                  >
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
                <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                          <v-icon
                            small
                            class="ml-2"
                            v-on="on"
                            @click="centreBalances(props.item)"
                          >
                            info
                          </v-icon>
                          </template>
                        <span>Centre Information</span>
                  </v-tooltip>
            </v-layout>

              </td>

            </template>
             <template v-slot:no-data>
              <h4>No records found</h4>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
              </v-alert>
            </template>
          </v-data-table>
          </v-flex>

<!-- Logo card -->
           <v-flex lg4 sm12 xs12>
             <v-card hover> 
            <v-flex class="card card2">
              <v-flex ma-1>
                <v-img
                  :src="`https://content-test.contipay.co.zw/assets/uploads/${logoImage}`"
                  :lazy-src="`https://content-test.contipay.co.zw/assets/uploads/${logoImage}`"
                  
                  
                >
                  <template v-slot:placeholder>
                    <v-layout
                      fill-height
                      align-center
                      justify-center
                      ma-0
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-layout>
                  </template>
                </v-img>
              </v-flex>
            </v-flex>
             </v-card>
          </v-flex>
          </v-layout>
          
<!-- dialog for user link to vas -->
    <v-dialog v-model="userLinkVASdialog" persistent max-width="600px">
      
      <v-card>
        <v-card-title class="blue">
          <span class="headline">User Loyalty Intergration</span>
        </v-card-title>
        <v-card-text>
        <v-layout wrap>
              
              
             <v-flex xs12>
               <v-combobox
                             v-model="Campaign"
                              item-text="NAME"
                              item-value="id"
                                                      
                              :items="selectedCampaignArray"
                             
                              chips
                              deletable-chips
                              label="Select campaign to add users"
                              
                              persistent-hint
                            >
              </v-combobox>
               
              </v-flex>
                
             <v-flex xs12>
               <v-combobox
                              v-model="usersLinked"
                              item-text="name"
                              item-value="id"
                                                      
                              :items="selectedUsersArray"
                              multiple
                              chips
                              deletable-chips
                              label="Select users to link to the above campaign to"
                              
                              persistent-hint
                            >
                </v-combobox>
               
              </v-flex>
            
              
            </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="userLinkVASdialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="userLinkVAS">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
<!-- dialog for centre charge configs -->
    <v-dialog v-model="CentreChargedialog" persistent max-width="600px">
      
      <v-card>
        <v-card-title class="blue">
          <span class="headline">Centre Charge Config</span>
        </v-card-title>
        <v-card-text>
        <v-layout wrap>
              
              
             <v-flex xs12>
               <v-combobox
                             v-model="currency_id"
                              item-text="iso_code"
                              item-value="id"
                                                
                              :items="selectedCentreCharge"
                             
                              chips
                              deletable-chips
                              label="Select currency to config"
                              
                              persistent-hint
                            >
              </v-combobox>
               
              </v-flex>
                
             <v-flex xs12>
               <v-combobox
                             v-model="is_charged"
                              item-text="name"
                              item-value="id"
                                                      
                              :items="is_chargedArr"
                              
                              chips
                              deletable-chips
                              label="Select who is to be charged per transaction"
                              
                              persistent-hint
                            >
                </v-combobox>
               
              </v-flex>
            <v-flex xs12>
                <v-text-field v-model="CentreCharge.base_charge" type="number" label="Base Charge" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="CentreCharge.percent_charge" type="number" label="Percentage Charge" required></v-text-field>
              </v-flex>
              
            </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="CentreChargedialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="saveCentreCharge">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


<!-- dialog for editing centre charge configs -->
    <v-dialog v-model="EditCentreChargedialog" persistent max-width="800px">
       <v-card>
        <v-card-title class="blue">
          <span class="headline">Edit Centre Configs</span>
           <v-spacer></v-spacer>
          <v-btn color="primary" text @click="EditCentreChargedialog = false">Close</v-btn>
         
        </v-card-title>
       

            <v-data-table
              :headers="headers"
              :items="centreConfigData"
            >
      <template v-slot:items="props">
        <td>{{props.item.iso_code}}</td>
        
        <td class="text-xs-right">
          <v-edit-dialog
            :return-value.sync="props.item.is_charged"
            large
            lazy
            persistent
            @save="save(props.item)"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            <div>{{ props.item.is_charged }}</div>
            <template v-slot:input>
              <div class="mt-3 title">Update is charged</div>
            </template>
            <template v-slot:input>
              <v-combobox
                             v-model="is_charged"
                              item-text="name"
                              item-value="id"                                                      
                              :items="is_chargedArr"                              
                              chips
                              deletable-chips
                              label="who is to be charged"                              
                              persistent-hint
                             
                            >
                </v-combobox>
              <!-- <v-text-field
                v-model="props.item.is_charged"
                label="Edit"
                single-line
                mask="#"
                autofocus
              ></v-text-field> -->
            </template>
          </v-edit-dialog>
        </td>

        <td class="text-xs-right">
          <v-edit-dialog
            :return-value.sync="props.item.base_charge"
            large
            lazy
            persistent
            @save="save(props.item)"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            <div>{{ props.item.base_charge }}</div>
            <template v-slot:input>
              <div class="mt-3 title">Update base charge</div>
            </template>
            <template v-slot:input>
              <v-text-field
                v-model="props.item.base_charge"
                
                label="Edit"
                single-line
                mask="###.##"
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </td>
       
        
        <td class="text-xs-right">
          <v-edit-dialog
            :return-value.sync="props.item.percent_charge"
            large
            lazy
            persistent
            @save="save(props.item)"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            <div>{{ props.item.percent_charge }}</div>
            <template v-slot:input>
              <div class="mt-3 title">Update % Charge</div>
            </template>
            <template v-slot:input>
              <v-text-field
                v-model="props.item.percent_charge"
               
                label="Edit"
                single-line
                type="number"
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </td>
      </template>
    </v-data-table>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn flat @click="snack = false">Close</v-btn>
    </v-snackbar>
       
        
       
      </v-card>
    </v-dialog>

    
<!-- Centre Balance dialog -->
<v-dialog v-model="centreBalance" persistent max-width="600px">
      
      <v-card>
        <v-card-title class="blue">
          <span class="headline">{{selectedCentreName}} Balance Summary</span>
        </v-card-title>
        <!-- <v-card-text> -->
           <v-data-table
            :headers="headersCentreBalances"
            :items="CentreBalancesData"
            hide-actions
            class="elevation-1"
          >
            <template v-slot:items="propsBal">
              <td >{{currencySymbol}} {{ propsBal.item.pending_balances }}</td>
              <td class="text-xs-left">{{currencySymbol}} {{ propsBal.item.matured_balances }}</td>
              <td class="text-xs-left">{{currencySymbol}} {{ propsBal.item.total_balances }}</td>
            

            </template>
             <template v-slot:no-data>
              <h4>No records found</h4>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
              </v-alert>
            </template>
          </v-data-table>
        <!-- </v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="centreBalance = false">Close</v-btn>
         
        </v-card-actions>
      </v-card>
    </v-dialog>



<!-- dialog for new VSA centre -->
    <v-dialog v-model="newVASCentredialog" persistent max-width="600px">
      
      <v-card>
        <v-card-title class="blue">
          <span class="headline">Create Loyalty Campaign</span>
        </v-card-title>
        <v-card-text>
           <v-layout wrap>
              
              <v-flex xs12>
                <v-text-field v-model="campaignName" label="Enter Loyalty Campaign Name" required></v-text-field>
              </v-flex>
            
              
            </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newVASCentredialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="newVASCentre">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

        </div>
    </template>
    <script>
    /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
    /* eslint-disable no-unused-vars */
    var Swal
    export default {
      props: ['PropData','PropCentre','PropSymbol','PropCurrency'],
      beforeMount() {
        this.currency = this.$props.PropCurrency
        this.currencySymbol = this.$props.PropSymbol
        this.initializeMerchant()
      },
      watch: {
        dialogMerchant (val) {
          val || this.closeMerchant()
        },
        usersLinked(val){
          //console.log(val)
        },
        Campaign(val){
          //console.log(val.NAME)
          this.campaignNameID = val.id
        },
        is_charged(val){
         if(val.id != null){
            this.CentreCharge.is_charged = val.id
         }
         
           //console.log(this.CentreCharge.is_charged)
        },
        currency_id(val){
          if(val.id != null){
             this.CentreCharge.currency_id = val.id
             //console.log(this.CentreCharge.currency_id)
         }
         
          //console.log(this.CentreCharge.currency_id)
        }
      },
        data() {
            return {
                 nodeApi: 'https://serv.contitouch.co.zw/',
                 //nodeApi : 'http://localhost:81/',
                MerchantData: [],
                dialogMerchant: false,
                EditCentreChargedialog: false,
                logoImage: '',
                Campaign: 0,
                selectedCentreName: '',
                currency: '',
                centreBalance: false,
                currencySymbol: '',
                CentreBalancesData: [],
                usersLinked: [],
                campaignName: '',
                campaignNameID: 0,
                 search: '',
                 centre_id:0,
                searchType: '',
                newVASCentredialog: false,
                CentreCharge: {
                 
                  centre_id:0,
                  base_charge: 0,
                  percent_charge:0,
                  is_charged:0,
                  currency_id:0
                },
                currency_id:'',
                is_charged:'',
                is_chargedArr: [
                 {name:'Customer is charged',id:0},
                 {name:'Centre is charged',id:1}
                ],
                userLinkVASdialog: false,
                CentreChargedialog: false,
                selectedCompanies: [],
                selectedCentreCharge:[],
                selectedCampaignArray: [],
                selectedUsersArray: [],
                 menuitems: [
                        { title: 'Charge Config' },
                        { title: 'Create Campaign' },
                        { title: 'Link Users to Campaign' }
                      ],
                menuitemsEdit:[
                        { title: 'Edit Charge Config' },
                        { title: 'Edit Campaign' },
                        { title: 'Edit Linked Users' }
                ],
                
                headersCentreBalances: [
                    {
                    text: 'Balance $',
                    align: 'left',
                    sortable: false,
                    value: 'balance'
                    },
                    { text: 'Matured $', value: 'company_name' },
                    // { text: 'Centre Logo', value: 'logo' },
                    { text: 'Total $', value: 'actions' },
                    
                ],
                headersMerchant: [
                  {
                    text: 'ID',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                    },
                    {
                    text: 'Centre Name',
                    align: 'left',
                    sortable: false,
                    value: 'Centre Name'
                    },
                    { text: 'Company Name', value: 'company_name' },
                    // { text: 'Centre Logo', value: 'logo' },
                    { text: 'Actions', value: 'actions' },
                    
                ],
                snack: false,
                    snackColor: '',
                    snackText: '',
                    max25chars: v => v.length <= 25 || 'Input too long!',
                    pagination: {},
                    headers: [
                      {
                        text: 'Currency',
                        align: 'left',
                        sortable: false,
                        value: 'name',
                      },
                      { text: 'Is Charged (0 = merchant, 1 = client)', value: 'calories' },
                      { text: 'Base Charge', value: 'fat' },
                      { text: '% Charge', value: 'carbs' }
                    ],
                    centreConfigData: [],
                editedIndexMerchant: -1,
                editedMerchant: {
                  centre_name: '',
                  company_name: '',
                  currencies: '',
                  logo: ''
                },
                defaultMerchant: {
                  centre_name: '',
                  company_name: '',
                  currencies: '',
                  logo: ''
                }
                
            }
        },
        methods: {
          logger(data){
            //console.log(data)
          },
         async centreBalances(centre){
            this.selectedCentreName = centre.centre_name
            
            await this.axios.post(this.nodeApi+'getCentreBalancesByID',{element1:centre.id,element2:this.currency})
            .then(res =>{
              //console.log(res.data)
              this.CentreBalancesData = res.data
            }).catch(err =>{

            })


            this.centreBalance = true

            //console.log(centre)
          },
          loadImage(image){
            this.logoImage = image
          },
          save (val) {
             
              let configData = {
                is_charged: '',
                base_charge: '',
                percent_charge: ''
              }
              configData.is_charged = this.CentreCharge.is_charged
              configData.base_charge = val.base_charge
              configData.percent_charge = val.percent_charge
               //console.log(configData)
              this.axios.put(this.nodeApi+'updateCentreCharges/'+val.id,{details: configData})
              .then(res =>{
                // this.snack = true
                // this.snackColor = 'success'
                // this.snackText = 'Data saved'

                Swal.fire(
                            'Centre Configs Edit !!',
                            'Configs where edited successfully.re-open to see changes',
                            'success'
                          )
                //this.EditCentreChargedialog = false
                 
              }).catch(err =>{
                this.snack = true
                this.snackColor = 'error'
                this.snackText = err
              })

              
            },
            cancel () {
              this.snack = true
              this.snackColor = 'error'
              this.snackText = 'Canceled'
            },
            open () {
              this.snack = true
              this.snackColor = 'info'
              this.snackText = 'Dialog opened'
            },
            close () {
              // eslint-disable-next-line no-console
              console.log('Dialog closed')
            },
          selectedMenuItemEdit(id,menu){
            if(menu == 'Edit Charge Config'){

              this.axios.post(this.nodeApi+'getCentreChargeConfigByID',{centre:id})
              .then(res =>{
                this.centreConfigData = res.data
              }).catch(err =>{

              })

              this.EditCentreChargedialog = true
            }

          },
          selectedMenuItem(id,menu){
          
           
            this.centre_id = id
            this.CentreCharge.currency_id = null
            this.CentreCharge.is_charged = null
            this.CentreCharge.base_charge = null
            this.CentreCharge.percent_charge = null
            this.usersLinked = []
            this.Campaign = ''
            this.campaignName = ''
            this.selectedCampaignArray = []
            this.selectedUsersArray = []
            this.is_charged = ''
            this.currency_id = ''
          
            if(menu == 'Charge Config'){
              //console.log(this.is_charged)
              this.axios.get(this.nodeApi+'getCurrencies2')
                .then(res =>{
                  
                  // let newjson = JSON.parse(res.data[0].currencies)
                  // let keys = Object.keys(JSON.parse(res.data[0].currencies))
                  // let values = Object.values(JSON.parse(res.data[0].currencies))
                  // let result = []
                  // //console.log(values)
                  
                  // for (let i = 0; i < values.length; i++) {
                   
                  //    result.push({id:keys[i],name:values[i] });
                     
                  // }
                  res.data.forEach(element => {
                      this.selectedCentreCharge.push(element)
                  });
                  
                  

                  //this.selectedCentreCharge = result
                  
                }).catch(err =>{

                })

                
               
              this.CentreChargedialog = true
               
            }
            if(menu == 'Link Users to Campaign'){


               this.axios.post(this.nodeApi+'getVASCentresByCentreID',{element1:id})
                .then(res =>{
                  
                  if(res.data.length > 0){
                     res.data.forEach(element => {
                      this.selectedCampaignArray.push(element)
                  });
                  }else{
                     this.userLinkVASdialog = false
                     Swal.fire({
                        type: "error",
                        title: "No Campaigns to link!!",
                        text: "Create a campaign for this centre"
                    });
                  }
                 
                  
                }).catch(err =>{

                })


               this.axios.post(this.nodeApi+'getUsersVASByID',{centre_id:this.centre_id})
                .then(res =>{
                  //console.log(res.data)
                  res.data.forEach(element => {
                      this.selectedUsersArray.push(element)
                  });
                  
                }).catch(err =>{

                })
              this.userLinkVASdialog = true
            }
            if(menu == 'Create Campaign'){
                
                this.newVASCentredialog  = true
            }
                
            
            
          },
        async saveCentreCharge(){
            
            this.CentreCharge.centre_id = this.centre_id
            
            //this.CentreCharge.company_id = this.com

         

          //console.log(this.CentreCharge)
        await this.axios.post(this.nodeApi+'addCentreChargeConfig',{chargeconfig:this.CentreCharge})
            .then(res =>{
              //console.log(res.data)
               if(res.data.affectedRows){
                 this.CentreChargedialog = false
                
                 Swal.fire(
                            'Centre Configs Added !!',
                            'Your new centre configs where saved successfully.',
                            'success'
                          )
              }else{
                 this.CentreChargedialog = false
                  Swal.fire({
                        type: "error",
                        title: "Centre Configs Error!!",
                        text: res.data.sqlMessage
                    });
              }
              
              

            }).catch(err =>{
               this.CentreChargedialog = false
              Swal.fire({
                        type: "error",
                        title: "Centre Configs Error!!",
                        text: err
                    });
            })



            this.CentreChargedialog = false
          },
         userLinkVAS(){
        // console.log(this.usersLinked)
        // console.log(this.campaignNameID)

         this.axios.post(this.nodeApi+'addVASCentreUser',{vas_centre_id:this.campaignNameID,users_ids:this.usersLinked})
            .then(res =>{
              //console.log(res.data)
               if(res.data.affectedRows){
                this.userLinkVASdialog = false
                
                 Swal.fire(
                            'Users Linked !',
                            'Your selected users have been linked successfully.',
                            'success'
                          )
              }else{
                this.userLinkVASdialog = false
                  Swal.fire({
                        type: "error",
                        title: "Can link user to campaign!!",
                        text: res.data.sqlMessage
                    });
              }
              
              

            }).catch(err =>{
              this.userLinkVASdialog = false
              Swal.fire({
                        type: "error",
                        title: "Can link user to campaign!!",
                        text: err
                    });
            })


            
           
      },
      async newVASCentre(){
          
            let detailsData = {
              company_id: 0,
              centre_id: this.centre_id,
              name: this.campaignName
            }
           

          await this.axios.post(this.nodeApi+'getCompanyIDByCentreID',{centre_id:this.centre_id})
            .then(res =>{
             // console.log(res.data)
              detailsData.company_id = res.data[0].company_id
            }).catch(err =>{
              Swal.fire({
                        type: "error",
                        title: "Can not get company id from centre!!",
                        text: err
                    });

            })

            this.axios.post(this.nodeApi+'addVASCentreSingle',{details:detailsData})
            .then(res =>{
              //console.log(res.data)
               if(res.data.affectedRows){
                this.newVASCentredialog = false
                
                 Swal.fire(
                            'Loyalty Centre Created!',
                            'Your loyalty centre has been created.',
                            'success'
                          )
              }else{
                  Swal.fire({
                        type: "error",
                        title: "Can not add centres!!",
                        text: res.data.sqlMessage
                    });
              }
              
              

            }).catch(err =>{
              Swal.fire({
                        type: "error",
                        title: "Can not add centre!!",
                        text: err
                    });
            })          
            

          },
             //edit Merchant Data
      editItemMerchant (item) {
        this.editedIndexMerchant = this.MerchantData.indexOf(item)
        this.editedMerchant = Object.assign({}, item)
        this.dialogMerchant = true
      },
      //Delete Merchant Data
      deleteItemMerchant (item) {
        const index = this.MerchantData.indexOf(item)
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.MerchantData.splice(index, 1)
          }
        })
       
      },


              closeMerchant () {
                this.dialogMerchant = false
                setTimeout(() => {
                this.editedMerchant = Object.assign({}, this.defaultMerchant)
                this.editedIndexMerchant = -1
                }, 300)
            },

            saveMerchant () {
                if (this.editedIndexMerchant > -1) {
                Object.assign(this.MerchantData[this.editedIndexMerchant], this.editedMerchant)
                } else {
                this.MerchantData.push(this.editedMerchant)
                }
                this.closeMerchant()
            },
             initializeMerchant () {
                //get All Merchants from the Database through the getCompanies API
                this.axios.get(this.nodeApi+'getCentres').then(data =>{
                this.MerchantData = data.data
                
                }).catch(err =>{
                Swal.fire({
                        type: "error",
                        title: "Can not load reports!!",
                        text: err
                    });
                })

                
            }
        },
    }
    </script>
    <style scoped>
    
    </style>
    
    