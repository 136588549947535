/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
<template>
<div>

<v-layout row wrap>
  <v-flex lg9 sm12 xs12>
    <v-flex>
    <v-card hover>
        
        <v-responsive>
          
          <v-chart :options="datacollection" autoresize class="echart">
          
          </v-chart>
        
        </v-responsive>    
      </v-card> 
    </v-flex>
  </v-flex>  

    <v-flex lg3 sm12 xs12>
       
      <!-- <v-layout row ma-0> -->
      <v-flex >
       <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="computedDateFormatted"
              label="Start Date"
              
              
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title @input="startDate"></v-date-picker>
        </v-menu>
        </v-flex>
        <v-flex>
       <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="computedDateFormatted2"
              label="End Date"
             
              
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" no-title @input="endDate"></v-date-picker>
        </v-menu>
      </v-flex>
      
      <!-- </v-layout> -->

     
      <v-card hover>
      <v-flex mb-2 class="card cardRev">
        <v-layout ma-1>
          <img src="./imgs/revenue.png" class="image">
          <v-flex style="text-align: right">
            <h5>Total Revenue</h5>
            
            <h5 class="h4Rev">${{parseFloat(revenueCharge).toFixed(2)}}</h5>
            <p>Total income</p>
          </v-flex>   
        </v-layout>       
      </v-flex>
      </v-card>

      <v-card hover>
      <v-flex mb-2 class="card cardExp">
        <v-layout ma-1>
          <img src="./imgs/expenses.png" class="image">
          <v-flex style="text-align: right">
            <h5>Total Disbursed</h5>
            
            <h5 class="h4Exp">${{parseFloat(totalDisbure).toFixed(2)}}</h5>
            <p>Disbursements</p>
          </v-flex> 
        </v-layout>
      </v-flex>
     </v-card>

       <v-card hover>
      <v-flex mb-2 class="card cardPro">        
        <v-layout ma-1>
          <img src="./imgs/profit.png" class="image">
          <v-flex style="text-align: right">
            <h5>Contipay Charges</h5>
           
            <h5 class="h4Pro">${{parseFloat(expenseCharge+expenseDisburse).toFixed(2)}}</h5>
            <p> Received + Sent</p>
          </v-flex> 
        </v-layout>        
      </v-flex>
      </v-card>
    </v-flex>
  
</v-layout>

<v-layout row wrap>
  <v-flex lg3 sm12 xs12>
    <v-flex>
    <v-card hover>
       
        <v-responsive>
          
          <v-chart :options="donutdataReceived" autoresize class="echart">
          
          </v-chart>
        
        </v-responsive>    
      </v-card> 
    </v-flex>
  </v-flex>  

  <v-flex lg3 sm12 xs12>
    <v-flex>
    <v-card hover>
       
        <v-responsive>
          
          <v-chart :options="donutdataDisbursed" autoresize class="echart">
          
          </v-chart>
        
        </v-responsive>    
      </v-card> 
    </v-flex>
  </v-flex>   


    <v-flex lg6 sm12 xs12>
      <v-card hover>
      <v-flex mt-1 class="card card2">
         <v-flex ma-1>
          <h4>Current Client Transactions </h4>
         </v-flex>   
          
        <v-data-table
            :headers="headers"
            :items="top5clients"
            
            hide-actions
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
            <template v-slot:items="props">
              <td>{{ props.item.tran_date }}</td>
              <td class="text-xs-centre">{{ props.item.description }}</td>
              <td class="text-xs-centre">{{ props.item.tran_name }}</td>
              
              <td class="text-xs-centre">${{ props.item.tran_amount }}</td>
             
              
            </template>
          </v-data-table>
        
      </v-flex>
      </v-card>
    </v-flex>


</v-layout>
</div>

</template>
    <script>
    /* eslint-disable no-unused-vars */
    /* eslint-disable no-undef */
    import ECharts from 'vue-echarts'
    import 'echarts/lib/chart/line'
    import 'echarts/lib/component/polar'
    export default {
     props: ['PropData','PropCentre','PropCurrency'],
      components: {
        'v-chart': ECharts
      },
      data() {
        
        return {
          center_id: 0,
          centreCurrency: '',
          selected: '',
          date: new Date(2019,1,1).toISOString().substr(0, 10),
          date2: new Date().toISOString().substr(0, 10),
          //dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
          menu1: false,
          menu2: false,
          revenueCharge:0,
          expenseCharge:0,
          expenseDisburse:0,
          totalDisbure:0,
          start_date: '',
          end_date: '',
          profit:0,
          datacollection: null,
          donutdataReceived: null,
          donutdataDisbursed: null,
           nodeApi: 'https://serv.contitouch.co.zw/',
          //nodeApi: 'http://localhost:81/',
          chargesDataPerMonth: [],
          disburseDataPerMonth: [],
          disburseDonutName: [],
          chargeDonutName:[], 
          centreCurrencyArray: [],
          disburseDonutData: [],
          chargeDonutData: [],
          top5clientsData:[],
          graphCurrencies: [],
           headers: [
          {
            text: 'Date',
            align: 'left',
            sortable: false,
            value: 'date'
          },
           
          { text: 'Description', value: 'description' },
          { text: 'Client Name', value: 'name',width:"10%" },
          { text: 'Amount', value: 'amount',width:"10%" },
          
         
        
          
        ],
        top5clients: []
        };
      },
      beforeMount() {
         
      },
      mounted() {
        this.center_id = this.$props.PropCentre
        this.centreCurrency = this.$props.PropCurrency
        this.getCurrencies(this.center_id)
       this.getCharges()
        this.getDisbursements()
        this.getDonutData()
        this.getTo5ClientTan()
        this.fillData();
        
        //console.log(this.center_id)
      },
      computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
      computedDateFormatted2 () {
        return this.formatDate(this.date2)
      }
    },
    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
       // console.log(val)
      },
      date2 (val) {
        this.dateFormatted = this.formatDate(this.date2)
        //console.log(val)
      },
      center_id(val){
       // console.log(val)
      }

    },
      methods: {
        startDate(val){
          this.menu1 = false
          this.date = val
          this.callAllFunctions()
          //console.log(this.start_date)
        },
        endDate(val){
          this.menu2 = false
          this.date2 = val
          this.callAllFunctions()
         // console.log(this.end_date)
        },
        callAllFunctions(val){
          // this.selected = val
          // this.getCurrencies(this.center_id)
          this.getCharges()
          this.getDisbursements()
          this.getDonutData()
          this.getTo5ClientTan()
          this.fillData();
          //console.log(val)

        },
        getCurrencies(data){
          this.axios.post(this.nodeApi+'getCurrenciesByCompanyCentreTrans',{centre_id:data})
          .then(res =>{
            this.graphCurrencies = res.data
            this.selected = res.data[0].tran_curr
            //console.log(this.graphCurrencies)
          }).catch(err =>{

          })
        },

        formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
        getTo5ClientTan(){
          this.axios.post(this.nodeApi+'getTop5ClientTransactions',{merchant:this.center_id})
          .then(res =>{
            this.top5clients = res.data
          }).catch(err =>{
            Swal.fire({
                  type: "error",
                  title: "Can not get Client data!!",
                  text: err
                }); 
          })
        },
        getDonutData(){
              this.disburseDonutName = []
              this.disburseDonutData = []
              this.totalDisbure  = 0
              this.expenseDisburse = 0
              this.chargeDonutName = []
              this.chargeDonutData = []
              this.revenueCharge = 0
              this.expenseCharge = 0

          this.axios.post(this.nodeApi+'getPaymentProviderTransactionsGraphChargeFilter',{centre_id:this.center_id,currency:this.centreCurrency,start_date:this.date,end_date:this.date2})
          .then(res =>{
            //console.log(res)
            res.data.forEach(element => {
              this.chargeDonutName.push(element.tran_provider)
              this.chargeDonutData.push({name:element.tran_provider,value:element.tran_amount})
              this.revenueCharge += element.tran_amount
              this.expenseCharge += element.tran_charge
            });
            // console.log(this.revenueCharge)
            // console.log(this.expenseCharge)
          }).catch(err =>{
            //  Swal.fire({
            //       type: "error",
            //       title: "Can not get Graph data!!",
            //       text: err
            //     }); 
          })

          this.axios.post(this.nodeApi+'getPaymentProviderTransactionsGraphDisburseFilter',{centre_id:this.center_id,currency:this.centreCurrency,start_date:this.date,end_date:this.date2})
          .then(res =>{
            res.data.forEach(element => {
              this.disburseDonutName.push(element.tran_provider)
              this.disburseDonutData.push({name:element.tran_provider,value:element.tran_amount})
              this.totalDisbure += element.tran_amount
              this.expenseDisburse += element.tran_charge
            });
            // console.log(this.totalDisbure)
            // console.log(this.expenseDisburse)
          }).catch(err =>{
            //  Swal.fire({
            //       type: "error",
            //       title: "Can not get Graph data!!",
            //       text: err
            //     }); 
          })
        },
        getCharges(){
          this.chargesDataPerMonth = []
            this.axios.post(this.nodeApi+'getPaymentMerchantTransactionsGraphChargeFilter',{centre_id:this.center_id,currency:this.centreCurrency,start_date:this.date,end_date:this.date2})
            .then(res =>{
                
                let x = 0
                for (let index = 0; index <= 11; index++) {
                  if(res.data[x] == undefined){
                      this.chargesDataPerMonth.push(0)
                  }else{
                    if(new Date(res.data[x].tran_date).getMonth() == index){
                     
                      this.chargesDataPerMonth.push(res.data[x].tran_amount)
                       x = x + 1
                    }else{
                      this.chargesDataPerMonth.push(0)
                    }
                    
                  }
                     
                }
                
            }).catch(err =>{
              //  Swal.fire({
              //     type: "error",
              //     title: "Can not get Graph data!!",
              //     text: err
              //   }); 

            })

        },
        getDisbursements(){
          this.disburseDataPerMonth = []
            this.axios.post(this.nodeApi+'getPaymentMerchantTransactionsGraphDisburseFilter',{centre_id:this.center_id,currency:this.centreCurrency,start_date:this.date,end_date:this.date2})
            .then(res =>{
                   let x = 0
                for (let index = 0; index <= 11; index++) {
                  if(res.data[x] == undefined){
                      this.disburseDataPerMonth.push(0)
                  }else{
                    if(new Date(res.data[x].tran_date).getMonth() == index){
                     
                      this.disburseDataPerMonth.push(res.data[x].tran_amount)
                       x = x + 1
                    }else{
                      this.disburseDataPerMonth.push(0)
                    }
                    
                  }
                     
                }
               
            }).catch(err =>{
                Swal.fire({
                  type: "error",
                  title: "Can not get Graph data!!",
                  text: err
                }); 
            })
        },
        fillData() {
          this.donutdataDisbursed = {
            title: {
                            text: 'Payments Sent',
                            left: 'center',
                            top: 20,
                            textStyle: {
                                color: '#424242'
                            }
                        },
                tooltip: {
                    trigger: 'item',
                    formatter: "{a} <br/>{b}: {c} ({d}%)"
                },
                legend: {
                    bottom: 10,
                    left: 'center',
                    data:this.disburseDonutData
                },
                series: [
                    {
                        name:'Payments Sent',
                        type:'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                show: true,
                                textStyle: {
                                    fontSize: '30',
                                    fontWeight: 'bold'
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        data:this.disburseDonutData
                    }
                ]
            }



          this.donutdataReceived = {
                    title: {
                            text: 'Payments Received',
                            left: 'center',
                            top: 20,
                            textStyle: {
                                color: '#424242'
                            }
                        },
                tooltip: {
                    trigger: 'item',
                    formatter: "{a} <br/>{b}: {c} ({d}%)"
                },
                legend: {
                    bottom: 10,
                    left: 'center',
                    data:this.chargeDonutName
                },
                series: [
                    {
                        name:'Payments Received',
                        type:'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                show: true,
                                textStyle: {
                                    fontSize: '30',
                                    fontWeight: 'bold'
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        data:this.chargeDonutData
                    }
                ]
                    }
          
          this.datacollection = {
           color: ['#003366', '#ff0101'],
           title: {
            text: 'Monthly Cash Flow',
              subtext: 'Total monthly transactions'
              },
              legend: {
                 bottom: 10,
                  left: 'center',
                  data: ['Total Received', 'Total Sent']
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  }
              },
              xAxis: {
                  type: 'category',
                  data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec']
              },
              yAxis: {
                  type: 'value'
              },
              series: [{
                  name: 'Total Received',
                  data: this.chargesDataPerMonth,
                  type: 'bar'
              },
              {
                  name: 'Total Sent',
                  data: this.disburseDataPerMonth,
                  type: 'bar'
              }
              ]
          }
        }
      }
    };
    </script>
    <style scoped>
    .image{
    width: 60px;
    height: 60px;
  }
table { border-collapse: collapse; }
tr { border: solid rgb(211, 211, 211); }
td { border: solid rgb(211, 211, 211); }
th { border: solid rgb(211, 211, 211); font-family: "Trebuchet MS", Arial, Verdana;
  font-size: 12px; }
    </style>