import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from "./router"
import MultiFiltersPlugin from './plugins/MultiFilters'
import axios from 'axios'
import VueAxios from 'vue-axios'
import cors from 'cors'

import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack

// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'

// If you want to use ECharts extensions, just import the extension package and it will work
// Taking ECharts-GL as an example:
// You only need to install the package with `npm install --save echarts-gl` and import it as follows
import 'echarts-gl'
import excel from 'vue-excel-export'
import VueRx from 'vue-rx'
import { Observable, Subscription, Subject } from 'rxjs'
import VueSession from 'vue-session'

Vue.use(VueSession)

Vue.use(VueRx, { Observable, Subscription, Subject })
Vue.use(require('vue-moment'));


Vue.use(excel)
// register component to use
Vue.component('v-chart', ECharts)

 
Vue.use(VueAxios, axios)
Vue.use(cors)
Vue.use(MultiFiltersPlugin);


Vue.config.productionTip = false



new Vue({
  router,
  
  render: h => h(App),
}).$mount('#app')
