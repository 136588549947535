<template>
 <div class="bgimage limiter">
		<div class="bgimage container-login100">
			<div id="loginbox" class="wrap-login100 p-l-50 p-r-50 p-t-50 p-b-20">
			
					<span class="login100-form-title p-b-15">
            <img src="./logo.png" class="img1 bounceIn">
           <p style="color:white">YOUR E-COMMERCE PAYMENT SOLUTION</p>
					</span>
          
					<div class="wrap-input100 validate-input m-b-16">
						<input v-model="loginDetails.username" class="input100" type="text" name="email" placeholder="Username">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<span class="lnr lnr-user"></span>
						</span>
					</div>

					<div class="wrap-input100 validate-input m-b-16">
						<input v-model="loginDetails.password" class="input100" type="password" name="pass" placeholder="Password">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<span class="lnr lnr-lock"></span>
						</span>
					</div>

					
					
					<div class="container-login100-form-btn p-t-5">
						<v-btn 
              :loading="loading"
              :disabled="loading"
              color="info"
              @click="login"
              large
              class="login100-form-btn">
							Login
						</v-btn>
					</div>
          <div class="container-login100-form-btn p-t-25">
						<a href="#" @click="forgot">
							Forgot Your Password?
						</a>
					</div>
		
			</div>
      <span class="login100-form-title p-b-20">
        <img src="./paymenticons2.png" class="img2">
			</span>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import md5 from 'js-md5'
export default{
  data() {
    return {
      loader: null,
      userPassReset: {
        password: '',
        api_token: ''
      },
      loading: false, 
      phpService: '/service/service',
      loginDetails: {
        username: '',
        password: ''
      }, 
       nodeApi: 'https://serv.contitouch.co.zw/',
      contipayAPI:'',
      loginApi : '/service/service?endPoint=request/service/AdminLogin',
      otpApi: '/service/service?endPoint=request/service/VerifyLogin'

    }
  },
  watch: {
      loader () {
        
        
      }
    },
  methods: {

    login(){
    /*
      let data = {
        first_name: 'Gregory',
        last_name: 'Shoniwa',
        email: 'gshoniwa@gmail.com',
        company_id: 1,
        id: 14,
        approve_as: 1,
        role: 1
      }
        
       this.$session.start()
       this.$session.set('user', data)

            this.$router.push({
                      name: "Dashboard",
                      params: { userdata: data }
                    });*/
    
                    


      this.loader = 'loading'
      const l = this.loader
        this[l] = !this[l]

      var headers = {
            
            'Content-Type': 'application/json',
            'Authorisation': 'SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=',
            'timestamp':'1556893117'
        }
      if (this.loginDetails.username && this.loginDetails.password) {
        let newPassword =  this.loginDetails.password;
        
        let dataLogin = {
           "userName": this.loginDetails.username,
           "password": newPassword
        }
        

       //console.log(newPassword)
        
        //First step calling the AdminLogin Api
        this.axios.post(this.phpService + '?endPoint=request/service/AdminLogin',{data: dataLogin},{headers:headers})
          .then(response => {
            if (response.data.status == 'Success' && response.data.code == 1) {
               this[l] = false

                this.loader = null
              Swal.fire({
                  title: 'Please Enter OTP Number',
                  input: 'number',
                  inputAttributes: {
                    autocapitalize: 'off'
                  },
                  showCancelButton: true,
                  confirmButtonText: 'Enter',
                  showLoaderOnConfirm: true,
                  allowOutsideClick: false,
                  preConfirm: (OTP) => {
                    let dataOTP = {
                          "userName": this.loginDetails.username,
                          "otp": OTP
                        }
                    return this.axios.post(this.phpService + '?endPoint=request/service/VerifyLogin',{data:dataOTP},{headers:headers})
                      .then(response => {
                        if (response.data.status != 'Success' && response.data.code != 1) {
                         
                          Swal.showValidationMessage(
                          `Request failed: ${response.data.message}`
                        )
                        }else{
                          return response.data
                        }
                        
                      })
                      .catch(error => {
                        Swal.showValidationMessage(
                          `Request failed: ${error}`
                        )
                      })
                  },
                  //allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    this.$session.start()
                    this.$session.set('user', result.value.details)
                    this.$router.push({
                      name: "Dashboard",
                      params: { userdata: result.value.details }
                    });
                                     
                })

            }else{
              Swal.fire({
              type: "error",
              title: "Can not Login!!",
              text: response.data.message
            });
             this[l] = false

              this.loader = null
            }
          }).catch(err =>{
            Swal.fire({
              type: "error",
              title: "Can not Login!!",
              text: err
            });
             this[l] = false

            this.loader = null
          })
      
       
          
      }else{
        Swal.fire({
          type: "error",
          title: "Can not Login!!",
          text: "Please Try Again."
        });
         this[l] = false

        this.loader = null
      }
      
  
        
    },
   async resetPassword(username,password,user_id){
        var headers = {
            
            'Content-Type': 'application/json',
            'Authorisation': 'SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=',
            'timestamp':'1556893117'
        }
        
        let hashusernamepassward = '';
		    let hashuser = '';
        let hashpass = '';
        let usernameData = {
          string: username
        }
         let passData = {
          string: password
        }
         
                     
		
      await this.axios.post(this.phpService+'?endPoint=request/service/getToken',{passHash:usernameData})
          .then(function (response) {
          // handle success
          hashuser = response.data.message
          //console.log(response);
          })
          .catch(function (error) {
          // handle error
          console.log(error);
          })
         //console.log(hashuser);
      await this.axios.post(this.phpService+'?endPoint=request/service/getToken',{passHash:passData})
          .then(function (response) {
          // handle success
          hashpass = response.data.message
          //console.log(response);
          })
          .catch(function (error) {
          // handle error
          console.log(error);
          })
          //console.log(hashpass);
          let usernamepassData = {
            string: hashuser+passData
          }
      await this.axios.post(this.phpService+'?endPoint=request/service/getToken',{passHash:usernamepassData})
          .then(function (response) {
          // handle success
          hashusernamepassward = response.data.message
          //console.log(response);
          })
          .catch(function (error) {
          // handle error
          console.log(error);
          })
         // console.log(hashusernamepassward);       
          this.userPassReset.api_token = hashusernamepassward
          this.userPassReset.password = hashpass

          

         await  this.axios.put(this.nodeApi+'updatePassword/'+user_id,{details:this.userPassReset})
                                    .then(res =>{
                                      //console.log(res.data)
                                      if(res.data.affectedRows){
                                        Swal.fire({
                                          type: "success",
                                          title: "Password Reset!!",
                                          text: "Your password been resest successfully,Please check your email for more inforation.",
                                          showConfirmButton: true
                                        });
                                      }else{
                                           Swal.fire({
                                            type: "error",
                                            title: "Password Reset!!",
                                            text: res.data.sqlMessage
                                          });
                                      }
                                      
                                    }).catch(err =>{
                                       Swal.fire({
                                        type: "error",
                                        title: "Password Reset!!",
                                        text: err
                                      });
                                    })

    },
   forgot(){
      Swal.mixin({
              input: 'text',
              confirmButtonText: 'OK',
              showCancelButton: true,
              progressSteps: ['1']
            }).queue([
              {
                title: 'Password Rest',
                text: 'Please enter your email address'
              }
            ]).then((result) => {
              if (result.value[0]) {
                let emailRester = result.value[0]
                this.axios.post(this.nodeApi+'getUserIdByEmail',{email:emailRester})
                  .then(res =>{
                      if(res.data.length > 0){
                        
                        let user_id = res.data[0].id
                        let fullname = res.data[0].name
                        let username = res.data[0].username
                        let temppass = ''

                        this.axios.post(this.nodeApi+'sendEmail',{name:fullname, email:emailRester})
                              .then(res =>{
                               
                                temppass = res.data
                                 if(temppass != ''){
                                  this.resetPassword(username,temppass,user_id)
                                  //console.log(this.userPassReset)
                                                                   
                          
                                  }

                                 
                              }).catch(err =>{
                                 Swal.fire({
                                  type: "error",
                                  title: "Password Reset!!",
                                  text: err
                                });
                              })
                       
                      }else{
                        Swal.fire({
                          type: "error",
                          title: "Password Reset!!",
                          text: "Unknown email please try again!!"
                        });
                      }
                  }).catch(err =>{
                    Swal.fire({
                      type: "error",
                      title: "Password Reset!!",
                      text: err
                    });
                  })
                
              }else{
                 Swal.fire({
                  type: "error",
                  title: "Password Reset!!",
                  text: "Failed to reset password, please enter valid email."
                });
              }
            })
    }
  }
}
 
   
</script>

<style scoped>

.img1 {
  max-width: 100%;
  height: auto;
}
.img2 {
  max-width: 35%;
  height: auto;
  min-width: 300px;
}
.wrap-login100{
  background: rgba(5, 5, 5, 0.39)
}
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
