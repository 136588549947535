/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
<template>
    <v-layout row wrap justify-end>
        <v-toolbar flat color="blue darken-4">
            <v-toolbar-title class="white--text">Approval Management</v-toolbar-title>
            <v-divider
              class="mx-2"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
           
          </v-toolbar>
        <v-flex xs12 sm6 md3>
            <v-text-field
                    append-icon="search"
                    label="Provider"
                    single-line
                    hide-details
                    @input="filterSearch"
            ></v-text-field>
        </v-flex>
        

        <v-flex xs12 sm6 md3>

            <v-menu
                    ref="show_start_date"
                    :close-on-content-click="false"
                    v-model="show_start_date"
                    :nudge-right="40"
                    :return-value.sync="start_date"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
            >
                <v-text-field
                        slot="activator"
                        v-model="start_date"
                        label="From"
                        prepend-icon="event"
                        readonly
                ></v-text-field>
                <v-date-picker
                        v-model="start_date"
                        @input="filterStartDate"
                ></v-date-picker>

            </v-menu>

        </v-flex>

        <v-flex xs12 sm6 md3>
            <v-menu
                    ref="show_end_date"
                    :close-on-content-click="false"
                    v-model="show_end_date"
                    :nudge-right="40"
                    :return-value.sync="end_date"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
            >
                <v-text-field
                        slot="activator"
                        v-model="end_date"
                        label="To"
                        prepend-icon="event"
                        readonly
                ></v-text-field>
                <v-date-picker
                        v-model="end_date"
                        @input="filterEndDate"
                ></v-date-picker>

            </v-menu>
        </v-flex>
        
        <v-flex xs12>


            <v-data-table

                   
                    :headers="headers"
                    :items="approvalActionList"
                    :pagination.sync="pagination"
                    
                    item-key="tran_provider"
                    class="elevation-1"
                   
                    
                    :search="filters"
                    :custom-filter="customFilter"
            >
                <template slot="headers" slot-scope="props">
                    <tr>
                       
                        <th
                                v-for="header in props.headers"
                                :key="header.text"
                                :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                                @click="changeSort(header.value)"
                        >
                            <v-icon small>arrow_upward</v-icon>
                            {{ header.text }}
                        </th>
                    </tr>
                </template>
                <template v-slot:items="props">
                    <tr >
                        
                        <td class="text-xs-left">{{ props.item.tran_id}}</td>
                        <td>{{ props.item.tran_date}}</td>
                        <td class="text-xs-center">{{ parseFloat(props.item.tran_amount).toFixed(2) }}</td>
                        <td class="text-xs-center">{{ parseFloat(props.item.tran_charge).toFixed(2) }}</td>
                        <td class="text-xs-center">{{ props.item.tran_merchant }}</td>
                        <td class="text-xs-center">{{ props.item.tran_account }}</td>
                        <td class="text-xs-center">{{ props.item.tran_provider }}</td>
                        
                        <td class="text-xs-center">
                         
                            
                            <v-flex v-if="props.item.tran_status == 'confirmed'">
                              <v-btn color="success" @click="Approve(props.item.tran_id)">Approve</v-btn>
                              <v-btn color="error" @click="Decline(props.item.tran_id)">Decline</v-btn>
                            </v-flex>
                            
                            <v-flex v-if="props.item.tran_status == 'pending'">
                              <v-btn color="success" @click="Confirm(props.item.tran_id)">Confirm</v-btn>
                              <v-btn color="error" @click="Decline(props.item.tran_id)">Decline</v-btn>
                            </v-flex>

                            

                            <v-flex v-if="props.item.tran_status == 'approved'">
                              <v-btn color="success" @click="Pay(props.item.tran_id)">Pay</v-btn>
                              <v-btn color="error" @click="Decline(props.item.tran_id)">Decline</v-btn>
                            </v-flex>
                           
                        

                        </td>
                    </tr>
                </template>
                <template v-slot:expand="props">
                  <v-card flat>
                    <v-card-text>{{props.item.tran_description}}</v-card-text>
                  </v-card>
                </template>
            </v-data-table>

        </v-flex>
        

    </v-layout>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import * as jsPDF from 'jspdf'
import * as autoTable from 'jspdf-autotable'
  export default {
    props: ['PropData','PropCentre','PropCurrency'],
    data: () => ({
        nodeApi: 'https://serv.contitouch.co.zw/',
       phpService: '/service/service?endPoint=request/service/notify',
       //nodeApi : 'http://localhost:81/',
      expand: false,
      show_start_date: false,
      centreCurrency: '',
      center_id: 0,
      start_date: null,
      show_end_date: false,
      end_date: null,
      filters: {
        search: '',
        added_by: '',
        start_date: null,
        end_date: null,
      },
      authors: ['charge', 'disburse'],
      pagination: {
        sortBy: 'date'
      },
      selected: [],

      //Excel data
      
        json_fields: {
                'tran_date': 'Date',
                'tran_amount': 'Amount',
                'tran_charge': 'Charge',
                'tran_merchant': 'Merchant',
                'tran_account': 'Account No',
                'tran_provider': 'Provider',
                'tran_method': 'Method',
                'tran_status': 'Status',
        },
        json_data: [
            
        ],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        //End of excel data


      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Date',
          align: 'left',
          value: 'date'
          
        },
        {
          text: 'Amount',
          value: 'amount'
        },
        {
          text: 'Charge',
          value: 'charge'
        },
        {
          text: 'Merchant',
          value: 'merchant'
        },
        {
          text: 'Account No.',
          value: 'account'
        },
        {
          text: 'Provider',
          value: 'provider'
        },
        {
          text: 'Status',
          align: 'right',
          value: 'status'
        }
      ],
      rows: [],
      approvalActionList : [],
      UserApprovalActions: []

      
    }),
    beforeMount() {
      
    },
    mounted() {
        //this.rows = this.$props.PropData
        this.center_id = this.$props.PropCentre
        this.centreCurrency = this.$props.PropCurrency
        this.getUserApprovalActions(this.$props.PropData.approve_as)
          window.setInterval(() => {
      // eslint-disable-next-line
          this.getUserApprovalActions(this.$props.PropData.approve_as)
                    
          }, 2000);
        //console.log(this.rows)
    },
    methods: {
       getUserApprovalActions(data){
      this.axios.post(this.nodeApi+'getUserActionByLevel',{element1: data}).then(res =>{
        this.UserApprovalActions = res.data
        this.axios.post(this.nodeApi+'getApprovalMerchantTransactionsMulti',{centre_id:this.center_id,currency:this.centreCurrency, action_from:this.UserApprovalActions[0].From,user_id:this.$props.PropData.id})
        .then(res =>{
          
          this.approvalActionList = res.data
          //console.log(res.data)
        }).catch(err =>{
          Swal.fire({
              type: "error",
              title: "Approval action Error!!",
              text: err
            });
        })
        //console.log(this.UserApprovalActions)
      }).catch(err =>{
        Swal.fire({
              type: "error",
              title: "Approval action Error!!",
              text: err
            });
      })
    },

      Approve(tran_id){
        var headers = {
            
            'Content-Type': 'application/json',
            'Authorisation': 'SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=',
            'timestamp':'1556893117'
        }
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Approve transaction!'
            }).then((result) => {
            if (result.value) {
              this.axios.post(this.nodeApi+'checkApprovalNumber',{centre_id:this.center_id,approve_as:this.$props.PropData.approve_as})
                  .then(res =>{
                    let numberofApprovers = res.data.length
                    this.axios.post(this.nodeApi+'checkApprovedByID',{tran_id:tran_id})
                    .then(res =>{
                      let numberofApproverdTran = res.data[0].tran_num
                      let cheker = numberofApprovers - numberofApproverdTran
                      //console.log(cheker)  
                      if(cheker <= 1){
                       this.axios.post(this.nodeApi+'getUserActionByLevel',{element1: this.$props.PropData.approve_as}).then(res =>{
                        this.UserApprovalActions = res.data
                         this.axios.post(this.nodeApi+'addApprovals', {transaction_id:tran_id,action_value:this.UserApprovalActions[0].To,user_id:this.$props.PropData.id,description:'Approved'})
                        .then(res =>{
                          this.axios.put(this.nodeApi+'updateTransaction/'+tran_id,{status:this.UserApprovalActions[0].To})
                        .then(res =>{
                            let notifyData = {
                              transactionId: tran_id
                            } 
                            this.axios.post(this.phpService,{data:notifyData})
                          .then(res =>{
                              //console.log(res.data)
                          }).catch(err =>{
                               //console.log(err)
                          })

                            Swal.fire(
                            'Approved!',
                            'Your transaction has been Approved.',
                            'success'
                            )
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                                              
                         
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                        //console.log(this.UserApprovalActions)
                      }).catch(err =>{
                        Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                      })

                       

                      }else if (cheker > 1){
                      this.axios.post(this.nodeApi+'getUserActionByLevel',{element1: this.$props.PropData.approve_as}).then(res =>{
                        this.UserApprovalActions = res.data
                         this.axios.post(this.nodeApi+'addApprovals', {transaction_id:tran_id,action_value:this.UserApprovalActions[0].To,user_id:this.$props.PropData.id,description:'Confirm'})
                        .then(res =>{
                          let notifyData = {
                              transactionId: tran_id
                            } 
                            this.axios.post(this.phpService,{data:notifyData})
                            .then(res =>{
                              //console.log(res.data)
                          }).catch(err =>{
                               //console.log(err)
                          })

                          Swal.fire(
                            'Approved!',
                            'Your transaction has been Approved.',
                            'success'
                            )
                         
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                        //console.log(this.UserApprovalActions)
                      }).catch(err =>{
                        Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                      })

                       
                      }
                    }).catch(err =>{
                       Swal.fire({
                              type: "error",
                              title: "Check Approved Transactions!!",
                              text: err
                            });
                    })
                  }).catch(err =>{
                     Swal.fire({
                              type: "error",
                              title: "Check Number of Approver!!",
                              text: err
                            });
                  })
                
            }
            })
        },
      Confirm(tran_id){
        var headers = {
            
            'Content-Type': 'application/json',
            'Authorisation': 'SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=',
            'timestamp':'1556893117'
        }
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm transaction!'
            }).then((result) => {
            if (result.value) {
              this.axios.post(this.nodeApi+'checkApprovalNumber',{centre_id:this.center_id,approve_as:this.$props.PropData.approve_as})
                  .then(res =>{
                    let numberofApprovers = res.data.length
                    this.axios.post(this.nodeApi+'checkApprovedByID',{tran_id:tran_id})
                    .then(res =>{
                      let numberofApproverdTran = res.data[0].tran_num
                      let cheker = numberofApprovers - numberofApproverdTran
                      //console.log(cheker)  
                      if(cheker <= 1){
                       this.axios.post(this.nodeApi+'getUserActionByLevel',{element1: this.$props.PropData.approve_as}).then(res =>{
                        this.UserApprovalActions = res.data
                         this.axios.post(this.nodeApi+'addApprovals', {transaction_id:tran_id,action_value:this.UserApprovalActions[0].To,user_id:this.$props.PropData.id,description:'Confirm'})
                        .then(res =>{
                          this.axios.put(this.nodeApi+'updateTransaction/'+tran_id,{status:this.UserApprovalActions[0].To})
                        .then(res =>{
                            let notifyData = {
                              transactionId: tran_id
                            } 
                            this.axios.post(this.phpService,{data:notifyData})
                           .then(res =>{
                              //console.log(res.data)
                          }).catch(err =>{
                               //console.log(err)
                          })
                          
                            Swal.fire(
                            'Confirmed!',
                            'Your transaction has been Confirmed.',
                            'success'
                            )
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                                              
                         
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                        //console.log(this.UserApprovalActions)
                      }).catch(err =>{
                        Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                      })

                       

                      }else if (cheker > 1){
                      this.axios.post(this.nodeApi+'getUserActionByLevel',{element1: this.$props.PropData.approve_as}).then(res =>{
                        this.UserApprovalActions = res.data
                         this.axios.post(this.nodeApi+'addApprovals', {transaction_id:tran_id,action_value:this.UserApprovalActions[0].To,user_id:this.$props.PropData.id,description:'Confirm'})
                        .then(res =>{
                           let notifyData = {
                              transactionId: tran_id
                            } 
                            this.axios.post(this.phpService,{data:notifyData})
                          .then(res =>{
                              //console.log(res.data)
                          }).catch(err =>{
                               //console.log(err)
                          })

                          Swal.fire(
                            'Confirmed!',
                            'Your transaction has been Confirmed.',
                            'success'
                            )
                         
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                        //console.log(this.UserApprovalActions)
                      }).catch(err =>{
                        Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                      })

                       
                      }
                    }).catch(err =>{
                       Swal.fire({
                              type: "error",
                              title: "Check Approved Transactions!!",
                              text: err
                            });
                    })
                  }).catch(err =>{
                     Swal.fire({
                              type: "error",
                              title: "Check Number of Approver!!",
                              text: err
                            });
                  })
                
            }
            })
        },
        Pay(tran_id){
          var headers = {
            
            'Content-Type': 'application/json',
            'Authorisation': 'SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=',
            'timestamp':'1556893117'
        }
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Pay transaction!'
            }).then((result) => {
            if (result.value) {
              //check the number of approver in the centre
              this.axios.post(this.nodeApi+'checkApprovalNumber',{centre_id:this.center_id,approve_as:this.$props.PropData.approve_as})
                  .then(res =>{
                    let numberofApprovers = res.data.length
                    this.axios.post(this.nodeApi+'checkApprovedByID',{tran_id:tran_id})
                    .then(res =>{
                      let numberofApproverdTran = res.data[0].tran_num
                      let cheker = numberofApprovers - numberofApproverdTran
                      //console.log(cheker)  
                      if(cheker <= 1){
                       this.axios.post(this.nodeApi+'getUserActionByLevel',{element1: this.$props.PropData.approve_as}).then(res =>{
                        this.UserApprovalActions = res.data
                         this.axios.post(this.nodeApi+'addApprovals', {transaction_id:tran_id,action_value:this.UserApprovalActions[0].To,user_id:this.$props.PropData.id,description:'Paid'})
                        .then(res =>{
                          this.axios.put(this.nodeApi+'updateTransaction/'+tran_id,{status:this.UserApprovalActions[0].To})
                        .then(res =>{

                           let notifyData = {
                              transactionId: tran_id
                            } 
                            this.axios.post(this.phpService,{data:notifyData})
                          .then(res =>{
                              //console.log(res.data)
                          }).catch(err =>{
                               //console.log(err)
                          })
                          
                            Swal.fire(
                            'Paid!',
                            'You have successfully approved transaction for payment.',
                            'success'
                            )
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                                              
                         
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                        //console.log(this.UserApprovalActions)
                      }).catch(err =>{
                        Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                      })

                       

                      }else if (cheker > 1){
                      this.axios.post(this.nodeApi+'getUserActionByLevel',{element1: this.$props.PropData.approve_as}).then(res =>{
                        this.UserApprovalActions = res.data
                         this.axios.post(this.nodeApi+'addApprovals', {transaction_id:tran_id,action_value:this.UserApprovalActions[1].To,user_id:this.$props.PropData.id,description:'Confirm'})
                        .then(res =>{
                           let notifyData = {
                              transactionId: tran_id
                            } 
                            this.axios.post(this.phpService,{data:notifyData})
                          .then(res =>{
                              //console.log(res.data)
                          }).catch(err =>{
                               //console.log(err)
                          })

                          Swal.fire(
                            'Paid!',
                            'You have successfully approved transaction for payment',
                            'success'
                            )
                         
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                        //console.log(this.UserApprovalActions)
                      }).catch(err =>{
                        Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                      })

                       
                      }
                    }).catch(err =>{
                       Swal.fire({
                              type: "error",
                              title: "Check Approved Transactions!!",
                              text: err
                            });
                    })
                  }).catch(err =>{
                     Swal.fire({
                              type: "error",
                              title: "Check Number of Approver!!",
                              text: err
                            });
                  })
                
            }
            })
        },
        Decline(tran_id){
          var headers = {
            
            'Content-Type': 'application/json',
            'Authorisation': 'SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=',
            'timestamp':'1556893117'
        }
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false,
                })

                swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Decline it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
                }).then((result) => {
                if (result.value) {
                 
                     this.axios.post(this.nodeApi+'getUserActionByLevel',{element1: this.$props.PropData.approve_as}).then(res =>{
                        this.UserApprovalActions = res.data
                        this.axios.put(this.nodeApi+'updateTransaction/'+tran_id,{status:this.UserApprovalActions[1].To})
                        .then(res =>{
                          let notifyData = {
                              transactionId: tran_id
                            } 
                            this.axios.post(this.phpService,{data:notifyData})
                         .then(res =>{
                              //console.log(res.data)
                          }).catch(err =>{
                               //console.log(err)
                          })
                          
                         swalWithBootstrapButtons.fire(
                          'Declined!',
                          'Your transaction has been Decline.',
                          'success'
                          )
                        }).catch(err =>{
                          Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                        })
                        //console.log(this.UserApprovalActions)
                      }).catch(err =>{
                        Swal.fire({
                              type: "error",
                              title: "Approval action Error!!",
                              text: err
                            });
                      })

                    

                } else if (
                    // Read more about handling dismissals
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Your transaction has not been changed :)',
                    'error'
                    )
                }
                })
        },
      exportPdf(){
        // let pdfName = 'TransactionsReport'; 
        //   var doc = new jsPDF();
        //   doc.text(this.rows, 10, 10);
        //   doc.save(pdfName + '.pdf');
        var vm = this
        var columns = [
           {
          title: 'Date',
         
          dataKey: 'tran_date'
        },
        {
          title: 'Amount',
          dataKey: 'tran_amount'
        },
        {
          title: 'Charge',
          dataKey: 'tran_charge'
        },
        {
          title: 'Merchant',
          dataKey: 'tran_merchant'
        },
        {
          title: 'Account No.',
          dataKey: 'tran_account'
        },
        {
          title: 'Provider',
          dataKey: 'tran_provider'
        },
        {
          title: 'Method',
          dataKey: 'tran_method'
        },
        {
          title: 'Status',
          dataKey: 'tran_status'
        }

        ]
        var doc = new jsPDF('p','pt')
        doc.autoTable(columns,vm.rows)
        doc.save('TransactionReport.pdf')
      },
      exportExcel(){

      },
      customFilter(items, filters, filter, headers) {
        // Init the filter class.
        const cf = new this.$MultiFilters(items, filters, filter, headers);
        // Use regular function(),
        // arrow functions does not allow context binding.
        // Register the global standard filter.
        cf.registerFilter('search', function (searchWord, items) {
          if (searchWord.trim() === '') return items;
          return items.filter(item => {
            return item.tran_provider.toLowerCase().includes(searchWord.toLowerCase());
          }, searchWord);
        });
        // Use regular function(),
        // arrow functions does not allow context binding.
        // Register "added_by" filter.
        cf.registerFilter('added_by', function (added_by, items) {
          // If the filter has not been applied yet
          // just return all available items.
          if (added_by.trim() === '') return items;
          // Compare each item added_by and just return the matching ones.
          return items.filter(item => {
            return item.tran_method.toLowerCase() === added_by.toLowerCase();
          }, added_by);
        });
        // Use regular function(),
        // arrow functions does not allow context binding.
        // Register "start_date" filter.
        cf.registerFilter('start_date', function (start_date, items) {
          // If the filter has not been applied yet
          // just return all available items.
          if (start_date === null) return items;
          // Compare each item start_date and just return the matching ones.
          return items.filter(item => {
            return item.tran_date >= start_date;
          }, start_date);
        });
        // Use regular function(),
        // arrow functions does not allow context binding.
        // Register "end_date" filter.
        cf.registerFilter('end_date', function (end_date, items) {
          // If the filter has not been applied yet
          // just return all available items.
          if (end_date === null) return items;
          // Compare each item end_date and just return the matching ones.
          return items.filter(item => {
            return item.tran_date <= end_date;
          }, end_date);
        });
        // Its time to run all created filters.
        // Will be executed in the order thay were defined.
        return cf.runFilters();
      },
      /**
       * Toggle selected items with the master checkbox.
       */
      toggleAll() {
        if (this.selected.length) {
          this.selected = [];
        } else {
          this.selected = this.rows.slice();
        }
      },
      /**
       * Column shorting.
       *
       * @param column
       */
      changeSort(column) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending;
        } else {
          this.pagination.sortBy = column;
          this.pagination.descending = false;
        }
      },
      /**
       * Handler when user input something at the "Filter" text field.
       */
      filterSearch(val) {
        this.filters = this.$MultiFilters.updateFilters(this.filters, {search: val});
      },
      /**
       * Handler when user  select some author at the "Author" select.
       */
      filterAuthor(val) {
        this.filters = this.$MultiFilters.updateFilters(this.filters, {added_by: val});
      },
      /**
       * Handler when select a date on "From" date picker.
       */
      filterStartDate(val) {
        // Close the date picker.
        this.$refs.show_start_date.save(val);
        //Convert the value to a timestamp before saving it.
        //const timestamp = new Date(val).getTime();
        this.filters = this.$MultiFilters.updateFilters(this.filters, {start_date: val});
      },
      /**
       * Handler when select a date on "To" date picker.
       */
      filterEndDate(val) {
        // Close the date picker.
        this.$refs.show_end_date.save(val);
        //Convert the value to a timestamp before saving it.
        //const timestamp = new Date(val).getTime();
        this.filters = this.$MultiFilters.updateFilters(this.filters, {end_date: val});
      },
    },
    filters: {
      /**
       * Format a timestamp into a d/m/yyy (because spanish format).
       *
       * @param value
       * @returns {string}
       */
      formatDate: function (value) {
        if (!value) return '';
        return new Date(value).toLocaleDateString("es-ES");
      }
    }
  };
</script>