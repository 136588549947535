<template>
  <v-app>
    
    <v-content>
      <router-view/>
    </v-content>
  </v-app>
</template>

<script>

export default {
 name: "App",
  components: {
    
  },
  data () {
    return {
      userID: 0
      //
    }
  }
}
</script>
<style>
 html{
      overflow-y: auto ;
    }
</style>
