/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
<template>
    


 <div>
          <v-toolbar flat color="blue darken-4">
            <v-toolbar-title class="white--text">Multiple Disbursements Managment</v-toolbar-title>
            <v-divider
              class="mx-2"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="multiDisburse" max-width="500px">
              <template v-slot:activator="{  }">
                <v-btn
                    v-show="checkerMultiDisburse == 'new'"
                    :loading="loading3"
                    :disabled="loading3"
                    color="primary"
                    class="white--text"
                    @click="$refs.doc.click()"
                   
                    >
                    Upload
                    <v-icon right dark>cloud_upload</v-icon>
                </v-btn>
                <input type="file" ref="doc" @change="uploader" v-if="uploadReady" style="display: none">
                
                <v-btn v-show="checkerMultiDisburse == 'new'"  color="red" dark class="mb-2" href="https://serv.contitouch.co.zw/downloadTemp" download>Download Temp</v-btn>

                <v-btn
                    v-show="checkerMultiDisburse == 'uploaded'"
                    :loading="loading3"
                    :disabled="loading3" 
                    color="primary"
                    class="white--text"
                    @click="postToServer"
                   
                    >
                    Process Table
                    <v-icon right dark>cloud_upload</v-icon>
                </v-btn>
                
                
                <v-btn v-show="checkerMultiDisburse == 'uploaded'" color="red" dark class="mb-2" @click="clearTable">Clear Table</v-btn>

              
              </template>
             <v-card>
                <v-card-title>
                  <span class="headline">Edit disbursement item</span>
                </v-card-title>

                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm6 md12>
                        <v-text-field v-model="editedMerchant.amount" label="Amount"></v-text-field>
                      </v-flex>
                     
                      <v-flex xs12 sm6 md12>
                        
                        
                          <v-select
                          v-model="editedMerchant.method_type"
                          item-text="name"
                          item-value="name"
                                                  
                          :items="multiDisburseMulti"
                        >
                          </v-select>
                                        
                      
                    </v-flex>
                    <v-flex xs12 sm6 md12>
                        <v-text-field v-model="editedMerchant.disbursement_account" label="Disbursement Account"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md12>
                        <v-textarea v-model="editedMerchant.description" label="Description"></v-textarea>
                    </v-flex>
                      
                      
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" flat @click="closeMerchant">Cancel</v-btn>
                  <v-btn color="blue darken-1" flat @click="saveMerchant">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-data-table
            :headers="headersMerchant"
            :items="csvData"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td>{{ props.item.amount }}</td>
              <td class="text-xs-left">{{ props.item.method_type }}</td>
              <td class="text-xs-left">{{ props.item.disbursement_account}}</td>
              <td class="text-xs-left">{{ props.item.description }}</td>
             
              <td class="justify-left">
                <v-icon
                  small
                  class="mr-2"
                  @click="addRecord"
                >
                  add
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click="editItemMerchant(props.item)"
                >
                  edit
                </v-icon>
                <v-icon
                  small
                  @click="deleteItemMerchant(props.item)"
                >
                  delete
                </v-icon>
              </td>
            </template>
            <template v-slot:no-data>
              <h5>No uploaded data</h5>
            </template>
          </v-data-table>
        </div>
    </template>
    <script>
    /* eslint-disable no-unused-vars */
    var Swal
    
    
    export default {
      
      beforeMount() {
        this.initializeMerchant()
      },
      watch: {
        csvData(val){
          if(val.length > 0){
            this.checkerMultiDisburse = 'uploaded'
          }
          
        },
        multiDisburse (val) {
          val || this.closeMerchant()
        },
        loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      }
      },
        data() {
            return {
              uploadReady: true,
                publicPath: process.env.BASE_URL,
                checkerMultiDisburse: 'new',
                csvData: [],
                nodeApi: 'https://serv.contitouch.co.zw/',
                 //nodeApi : 'http://localhost:81/',
                multiDisburseMulti: [],
                loader: null,
                loading3: false,
                multiDisburse: false,
                 search: '',
                 uploadedFile: null,
                searchType: '',
                selectedCompanies: [],
                files: '',
                headersMerchant: [
                    { 
                    text: 'Amount',
                    align: 'left',
                    sortable: false,
                    value: 'name'
                    },
                    { text: 'Method Type', value: 'reg_number' },
                    { text: 'Disbursement Account', value: 'vat_number' },
                    { text: 'Description', value: 'address' },
                   
                    { text: 'Actions', value: 'name', sortable: false }
                ],
                editedIndexMerchant: -1,
                editedMerchant: {
                  amount: '',
                  method_type: '',
                  disbursement_account: '',
                  description: ''
                },
                defaultMerchant: {
                  amount: '',
                  method_type: '',
                  disbursement_account: '',
                  description: ''
                }
                
            }
        },
        methods: {
          postToServer(){
              this.axios.post('api-test.contipay.co.zw',{data:this.csvData})
              .then(res =>{
                // console.log(res.data )
                  //this.rows = res.data                
                 
              
              }).catch(err =>{
                  this.progress = false
                        Swal.fire({
                        type: "error",
                        title: "Can not load reports!!",
                        text: err
                  });
              })
            
            // console.log(this.csvData)
            this.uploadReady = false
            this.$nextTick(() => {
              this.uploadReady = true
            })
             this.files = ''
            this.csvData = []
            this.checkerMultiDisburse = 'new'
            
          },
          clearTable(){
            this.uploadReady = false
            this.$nextTick(() => {
              this.uploadReady = true
            })
            this.files = ''
            this.csvData = []
           this.checkerMultiDisburse = 'new'
            
          },
          uploader(e){
            
            let this_ = this
            this.loader = this.loading3
            this.files = this.$refs.doc.files[0]

            if(this.files != ''){
              let formData = new FormData();
                formData.append('doc', this.files);
      
               this.axios.post('http://dev.contipay.co.zw:81/upload',
                    formData,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                  }
                ).then(function(data){
                  
                 
                  const request=require('request')
                  const csv=require('csvtojson')

                  
                  
                  csv()
                  .fromStream(request.get('http://dev.contipay.co.zw:81/uploadedFile/'+data.data.msg.filename))
                  .then((json)=>{
                   this_.csvData = json
                    Swal.fire({
              
                      type: "success",
                      title: "Document Uploaded!!",
                      text: "You document was uploaded successfully",
                      showConfirmButton: true
                    });
                   
                  }).catch(err =>{
                       Swal.fire({
                        type: "error",
                        title: "Can not convert csv!!",
                        text: err
                    });
                
                  })

                     
                })
                .catch(function(err){
                   Swal.fire({
                        type: "error",
                        title: "Can not upload document!!",
                        text: err
                    });
                
                });
                }
           
          },
             //edit Merchant Data
      addRecord(){
         this.multiDisburse = true
      },
      editItemMerchant (item) {
        this.editedIndexMerchant = this.csvData.indexOf(item)
        this.editedMerchant = Object.assign({}, item)
        this.multiDisburse = true
      },
      //Delete Merchant Data
      deleteItemMerchant (item) {
        const index = this.csvData.indexOf(item)
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.csvData.splice(index, 1)
          }
        })
       
      },


              closeMerchant () {
                this.multiDisburse = false
                setTimeout(() => {
                this.editedMerchant = Object.assign({}, this.defaultMerchant)
                this.editedIndexMerchant = -1
                }, 300)
            },

            saveMerchant () {
                if (this.editedIndexMerchant > -1) {
                Object.assign(this.csvData[this.editedIndexMerchant], this.editedMerchant)
                } else {
                this.csvData.push(this.editedMerchant)
                }
                this.closeMerchant()
            },
             initializeMerchant () {
                //get All Merchants from the Database through the getCompanies API
                this.axios.get(this.nodeApi+'getProviders').then(data =>{
                this.multiDisburseMulti = data.data
                
                }).catch(err =>{
                Swal.fire({
                        type: "error",
                        title: "Can not load reports!!",
                        text: err
                    });
                })

                
            }
        },
    }
    </script>
    <style scoped>
    .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
    
    </style>
    
    