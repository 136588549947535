<template>
      <div>
          <v-toolbar flat color="blue darken-4">
            <v-toolbar-title class="white--text">User Administration</v-toolbar-title>
            <v-divider
              class="mx-2"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogUser" max-width="800px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">New User</v-btn>
               
              </template>
              
               
                
                  <v-stepper v-model="e1">
                    <v-stepper-header>
                      <v-stepper-step editable :complete="e1 > 1" step="1">Personal Information</v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step editable :complete="e1 > 2" step="2">Company Information</v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step editable step="3">System Information</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <v-stepper-content step="1" >
                        <v-layout wrap justify-center>
                          
                          <v-flex xs12 sm6 md5 mr-5>
                            <v-text-field v-model="editedUser.first_name" label="First Name"></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md5>
                            <v-text-field v-model="editedUser.last_name" label="Last Name"></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md5 mr-5>
                            <v-text-field v-model="editedUser.email" label="Email Address"></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md5 > 
                            <v-text-field type="number" v-model="editedUser.cell" label="Contacts"></v-text-field>
                          </v-flex>
                                           
                      
                    </v-layout>
                  

                        <v-btn
                          color="primary"
                          @click="e1 = 2"
                        >
                          Continue
                        </v-btn>

                       
                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <v-layout wrap justify-center>
                          
                          <v-flex xs12 sm6 md5 mr-5>
                              <v-select
                              v-model="selectedCompanies"
                              label="Select Companies"
                              item-text="name"
                              item-value="id"
                                                      
                              :items="companyList"
                              multiple
                              chips
                              deletable-chips
                              hint="Select companies the user should access"
                              persistent-hint
                              @input="getCentres"
                            > 
                              </v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md5>
                             <v-select
                             v-model="selectedCentres"
                              label="Select Centre"
                              item-text="centre_name"
                              item-value="merchant"
                                                      
                              :items="centreList"
                              multiple
                              chips
                              deletable-chips
                              hint="Select the centres the use should access"
                              persistent-hint
                            >
                            </v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md5 mr-5>
                            <v-select
                            v-model="editedUser.role"
                              label="Select Role"
                              item-text="name"
                              item-value="value"
                                                      
                              :items="userRoles"
                            >
                            </v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md5 >
                            <v-select
                             v-model="editedUser.approve_as"
                              label="Select Approval Level"
                              item-text="name"
                              item-value="value"
                                                      
                              :items="approvalLevel"
                            >
                             </v-select>
                          </v-flex>
                                           
                      
                    </v-layout>

                        <v-btn
                          color="primary"
                          @click="e1 = 3"
                        >
                          Continue
                        </v-btn>

                        <v-btn text @click="e1 = 1">back</v-btn>
                      </v-stepper-content>

                      <v-stepper-content step="3">
                        <v-layout wrap justify-center>
                          
                          <v-flex xs12 sm6 md10>
                            <v-text-field v-model="editedUser.username" label="User Name"></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md5 mr-1>
                            <v-text-field type="password" v-model="editedUser.password" label="Password"></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md5 >
                            <v-text-field type="password" v-model="confirm_pass" label="Confirm Password"></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md10 >
                            <v-textarea v-model="editedUser.value" label="Public Key"></v-textarea>
                          </v-flex>
                                           
                      
                    </v-layout>

                        <v-btn
                        v-show="!editCheck"
                          color="primary"
                          @click="saveNewUser"
                        >
                          Save
                        </v-btn>

                        <v-btn
                          v-show="editCheck"
                          color="primary"
                          @click="saveUser"
                        >
                          Update
                        </v-btn>
                        

                        <v-btn text @click="e1 = 2">back</v-btn>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
               

                
              
            </v-dialog>
          </v-toolbar>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="headersUsers"
            :key="UsersData.username"
            :items="UsersData"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td>{{ props.item.id }}</td>
              <td class="text-xs-left">{{ props.item.username}}</td>
              <td class="text-xs-left">{{ props.item.first_name }} {{ props.item.last_name}}</td>
              
              <td class="text-xs-left">{{ getRole(props.item.role)}}</td>
              <td class="text-xs-left">{{ props.item.email }}</td>
              <td class="justify-left">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItemUser(props.item)"
                >
                  edit
                </v-icon>
                <v-icon
                  small
                  @click="deleteItemUser(props.item)"
                >
                  delete
                </v-icon>
              </td>
            </template>
            <template v-slot:no-data>
              <h4>No records found</h4>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
              </v-alert>
            </template>
          </v-data-table>
        </div>
</template>
<script>
 /* eslint-disable */
import md5 from 'js-md5'
export default {
  props: ['PropData'],
  created(){
  
  },
    beforeMount() {
      
      this.getCompanies()
      // this.getCentres()
      this.initializeUser()
    },
    watch: {
        dialogUser (val) {
        val || this.closeUser()
        
      },
      selectedCompanies(val){
        //console.log(val)
        if(val.length == 0){
          this.selectedCentres = []
        }
      },
      selectedCentres(val){
        //console.log(val)
      }
    }, 
    data() {
        return {
            e1: 0,
            editCheck: false,
             nodeApi: 'https://serv.contitouch.co.zw/',
            // nodeApi : 'http://localhost:81/',
            dialogUser: false,
             phpService: '/service/service',
            editedUserID: 0,
            UsersData: [],
            search: '',
            searchType: '',
            userCompanyConfig: [],
            userMetaData: [],
            selectedCompanies: [],
            
            selectedCentres: [],
            companyList: [],
            centreList: [],
            userRoles : [
              {
                name: 'Contipay Admin',
                value: 1
              },
              {
                name: 'Contipay Support',
                value: 2
              },
              {
                name: 'Merchant Admin',
                value: 3
              },
              {
                name: 'Merchant User',
                value: 4
              }
              ,
              {
                name: 'Contipay Agent',
                value: 5
              }
            ],
            approvalLevel : [
              {
                name: 'Clerk',
                value: 0
              },
              {
                name: 'Officer',
                value: 1
              },
              {
                name: 'Superviser',
                value: 2
              },
              {
                name: 'Manager',
                value: 3
              }
            ],
            headersUsers: [
              { text: 'User ID', value: 'id' },
                {
                text: 'User Name',
                align: 'left',
                sortable: false,
                value: 'username'
                },
                { text: 'Full Name', value: 'fullname' },
                
                { text: 'User Type', value: 'usertype' },
                { text: 'Email', value: 'email' },
                { text: 'Actions', value: 'username', sortable: false }
            ],
            editedIndexUser: -1,
            search: '',
            editedUser: {
              id: 0,
              username: '',
              first_name: '',
              last_name: '',
              email: '',
              cell: 0,
              status: 1,
              company_id: 0,
              department: 0,
              role: 0,
              approve_as: 0,
              password: '',
              
              value: ''

            },
            confirm_pass: ''
        }
    },
    methods: {
  
//Save new user details
     async saveNewUser(){
        //this.editedUser.password = md5(this.editedUser.password)
        var headers = {
            
            'Content-Type': 'application/json',
            'Authorisation': 'SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=',
            'timestamp':'1556893117'
        }
        
        let hashusernamepassward = '';
		    let hashuser = '';
        let hashpass = '';
        let userid = 0
        this.editedUser.department = this.selectedCentres[0]
        this.editedUser.company_id = this.selectedCompanies[0]
        let companyConfigArray = []
        let companyConfigArrayFinal = []
        let centreArray = []
        let step = 0
        let message = 'Saving....'
        
     Swal.fire({
              type: "success",
              title: "Users adding processing",
              text: message,
              onBeforeOpen: () => {
                
              Swal.showLoading()
              }
               
            });
       
		
      await this.axios.post(this.phpService + '?endPoint=request/service/getToken',{string:this.editedUser.username},{headers:headers})
          .then(function (response) {
          // handle success
          hashuser = response.data.message
          //console.log(response);
          })
          .catch(function (error) {
          // handle error
          console.log(error);
          })
         //console.log(hashuser);
      await this.axios.post(this.phpService + '?endPoint=request/service/getToken',{string:this.editedUser.password},{headers:headers})
          .then(function (response) {
          // handle success
          hashpass = response.data.message
          //console.log(response);
          })
          .catch(function (error) {
          // handle error
          console.log(error);
          })
          //console.log(hashpass);
      await this.axios.post(this.phpService + '?endPoint=request/service/getToken',{string:hashuser+hashpass},{headers:headers})
          .then(function (response) {
          // handle success
          hashusernamepassward = response.data.message
          //console.log(response);
          })
          .catch(function (error) {
          // handle error
          console.log(error);
          })
         // console.log(hashusernamepassward);       
       

if(step == 0){
       await this.axios.post(this.nodeApi+'addUsers',{api_token:hashusernamepassward, username: this.editedUser.username,first_name:this.editedUser.first_name,last_name:this.editedUser.last_name,email:this.editedUser.email,cell:this.editedUser.cell,role:this.editedUser.role,status:1,approve_as:this.editedUser.approve_as,password:hashpass,created_by:this.$session.get('user').id,group:this.editedUser.role})
        .then((res) =>{
         // console.log(res.data)
          if(res.data.affectedRows){
            
            userid = res.data.insertId
             this.selectedCentres.forEach(element => {
                centreArray.push({user_id:userid,centre_id:element})
              });
              message = 'Added user details'
             step += 1
          }else{
            Swal.fire({
                        type: "error",
                        title: "Can not add user!!",
                        text: res.data.sqlMessage
                    });
          }
          
              
          
        }).catch(err =>{

        })
        await this.axios.post(this.nodeApi+'getCentresFilter',this.selectedCompanies)
          .then(res =>{
            companyConfigArray = res.data
            
            res.data.forEach(element => {
              companyConfigArrayFinal.push({user_id:userid,company_id:element.company_id,merchant:element.merchant,level_value:this.editedUser.approve_as})
            });
              
          }).catch(err =>{

          })
        // console.log(companyConfigArray)
        
        // console.log(companyConfigArray)
        
       
}
if(step == 1){
        await this.axios.post(this.nodeApi+'addUserCentre',centreArray)
          .then(res =>{
            
          if(res.data.affectedRows){
           
            step += 1  
            message = 'Added user centres' 
          }else{
            
            Swal.fire({
                        type: "error",
                        title: "Can not add centres!!",
                        text: res.data.sqlMessage
                    });
          }
          
              
          }).catch(err =>{

          })
          
}
if(step == 2){
        await this.axios.post(this.nodeApi+'addUserCompanyConfig',companyConfigArrayFinal)
                .then(res =>{
                    if(res.data.affectedRows){
                   message = 'Added user company configs'
                    
                     step += 1
                  }else{
                    Swal.fire({
                                type: "error",
                                title: "Can not add user company config!!",
                                text: res.data.sqlMessage
                            });
                  }
                  

                }).catch(err =>{

                })
               
}
if(step == 3){
         await this.axios.post(this.nodeApi+'addUserMetaData',{uid:userid,key:'pub_cert',value:this.editedUser.value})
                    .then(res =>{
                        if(res.data.affectedRows){
                           message = 'Added user meta data'
                          
                           step += 1
                          Swal.fire(
                            'User Created!',
                            'Your user has been created.',
                            'success'
                          )

                          
                          
                        }else{
                          Swal.fire({
                                      type: "error",
                                      title: "Can not add meta data!!",
                                      text: res.data.sqlMessage
                                  });
                        }
                     
                      


                    }).catch(err =>{

                    })

                   
                   
}
        //   //console.log(res.data.insertId)
        
         this.getCompanies()
        this.dialogUser = false
        this.selectedCompanies = []
        this.selectedCentres = []
        this.editedUser.first_name = ''
        this.editedUser.last_name = ''
        this.editedUser.email = ''
        this.editedUser.cell = ''
        this.editedUser.username = ''
        this.editedUser.password = ''
        this.editedUser.value = ''
        this.confirm_pass = ''
        this.e1 = 1
        
        //this.editedUser.created_by = this.$session.get('user').id
      },
     async getCompanies(){
       
       //this.editedUser.created_by = this.$session.get('user').id
        //get All Merchants from the Database through the getCompanies API
             await this.axios.get(this.nodeApi+'getCompanies').then(data =>{
                this.companyList = data.data
                
                }).catch(err =>{
                Swal.fire({
                        type: "error",
                        title: "Can not load companies!!",
                        text: err
                    });
                })
      },
    async getCentres(){
        this.centreList = [] 
        let centreslist = []
        if(this.selectedCompanies.length > 0){
        //get All Merchants from the Database through the getCompanies API
            await this.axios.post(this.nodeApi+'getCentresFilter',this.selectedCompanies).then(data =>{
                 this.centreList = data.data
                
                }).catch(err =>{
                Swal.fire({
                        type: "error",
                        title: "Can not load centres!!",
                        text: err
                    });
                })
        //this.centreList = centreslist
       // console.log(this.centreList)
        }
      },
      //edit User Data
    async editItemUser (item) {
      this.editedUserID = item.id
      //console.log(item)
        let companies = []
        let centres = []
        let meta = ''
        await this.axios.post(this.nodeApi+'getUserCompanyConfigByID',{user_id:item.id})
        .then((res) => {
            this.userCompanyConfig = res.data
        }).catch(err =>{

        })
        await this.axios.post(this.nodeApi+'getUserCentresByID',{user_id:item.id})
        .then((res) => {
          companies = res.data
          centres = res.data
        }).catch(err =>{
          
        })
        await this.axios.post(this.nodeApi+'getUserMetaByID',{user_id:item.id})
        .then(res => {
         meta = res.data[0].value
         
        }).catch(err =>{
          
        })
        
        this.editedIndexUser = this.UsersData.indexOf(item)
        this.editedUser = Object.assign({}, item)
        this.editCheck = true
        //this.getCentres()
        
        this.centreList = centres
        this.selectedCentres = centres
        this.selectedCompanies = companies
        this.editedUser.value = meta
        
        this.dialogUser = true
      },
      //Delete User Data
     deleteItemUser (item) {
        const index = this.UsersData.indexOf(item)
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
           
          if (result.value) {
             this.axios.put(this.nodeApi+'updateUsers/'+item.id,{status: 0})
            .then(res =>{
                Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.UsersData.splice(index, 1)
              //console.log(res.data)
            }).catch(err =>{
              
            })
            
          }
        })
       
      },
      //get Role for User
      getRole(role){
        if(role == 1){
          return 'Contipay Amin'
        }
        if(role == 2){
           return 'Contipay Support'
        }
        if(role == 3){
           return 'Merchant Admin'
        }
        if(role == 4){
           return 'Merchant Support'
        }
      },
    
    
    //this code is for the user admin dialog
      closeUser () {
        this.editCheck = false
        this.dialogUser = false
        setTimeout(() => {
          this.editedUser = Object.assign({}, this.defaultUser)
          this.editedIndexUser = -1
        }, 300)
      },


//save edited user details
   async saveUser () {
     
     let companyConfigArrayFinal = []
     let centreArray = []
     let message = 'Updating....'
      let userid = this.editedUserID
     
      this.editedUser.password = ''
		  
      //console.log(this.selectedCentres)
      if(this.selectedCentres[0].id){
        this.centreList.forEach(element => {
                centreArray.push({user_id:this.editedUserID,centre_id:element.merchant})
                
              });

              //console.log(centreArray)
      }else{
      this.selectedCentres.forEach(element => {
                centreArray.push({user_id:this.editedUserID,centre_id:element})

              });
            //console.log(centreArray)  
      }
     Swal.fire({
              type: "success",
              title: "Users update processing",
              text: message,
              onBeforeOpen: () => {
                
              Swal.showLoading()
              }
               
            });
         
         
         
     
     await this.axios.put(this.nodeApi+'updateUsersMeta/'+this.editedUserID,{value:this.editedUser.value})
     .then(res =>{
        message = 'Updating user meta data'
       //console.log(res.data)
     }).catch(err =>{

     })
     await this.axios.put(this.nodeApi+'updateUsers/'+this.editedUserID,{username: this.editedUser.username,first_name:this.editedUser.first_name,last_name:this.editedUser.last_name,email:this.editedUser.email,cell:this.editedUser.cell,role:this.editedUser.role,approve_as:this.editedUser.approve_as})
     .then(res =>{
        message = 'Updating user details'
      // console.log(res.data)
     }).catch(err =>{
       
     })

     
if(!this.selectedCompanies[0].centre_name){

     await this.axios.post(this.nodeApi+'deleteUserCompanyConfigByID',{user_id:this.editedUserID})
     .then(res =>{
       //console.log(res.data)
       message = 'Deleting previous company configs'
     }).catch(err =>{

     })
     await this.axios.post(this.nodeApi+'deleteUserCentresByID',{user_id:this.editedUserID})
     .then(res =>{
        message = 'Deleting previous user centres'
       //console.log(res.data)
     }).catch(err =>{
       
     })

      await this.axios.post(this.nodeApi+'addUserCentre',centreArray)
          .then(res =>{
            //console.log(res.data)
          if(res.data.affectedRows){
          // console.log(res.data)
             message = 'Adding new centre to user'
             
          }else{
            
            Swal.fire({
                        type: "error",
                        title: "Can not add centres!!",
                        text: res.data.sqlMessage
                    });
          }
          
              
          }).catch(err =>{

          })
      
      

await this.axios.post(this.nodeApi+'getCentresFilter',this.selectedCompanies)
          .then(res =>{
            //console.log(res.data)
            message = 'Filtering centres'
            res.data.forEach(element => {
              companyConfigArrayFinal.push({user_id:userid,company_id:element.company_id,merchant:element.merchant,level_value:this.editedUser.approve_as})
            });
              
          }).catch(err =>{

          })
      await this.axios.post(this.nodeApi+'addUserCompanyConfig',companyConfigArrayFinal)
                .then(res =>{
                  
                    if(res.data.affectedRows){
                    //console.log(res.data)
                    Swal.fire(
                            'User Updated!',
                            'Your user has been updated.',
                            'success'
                          )
                     
                  }else{
                    Swal.fire({
                                type: "error",
                                title: "Can not add user company config!!",
                                text: res.data.sqlMessage
                            });
                  }
                  

                }).catch(err =>{

                })
       

}else{
  Swal.fire(
                            'User Updated!',
                            'Your user has been updated.',
                            'success'
                          )
}
       
        if (this.editedIndexUser > -1) {
          Object.assign(this.UsersData[this.editedIndexUser], this.editedUser)
        } else {
          this.UsersData.push(this.editedUser)
        }
        this.e1 = 1
        this.closeUser()
      },
        
      initializeUser () {
        //get All users from the Database through the getUsers API
        this.axios.get(this.nodeApi+'getUsers').then(data =>{
          this.UsersData = data.data
          
        }).catch(err =>{
           Swal.fire({
                type: "error",
                title: "Can not load reports!!",
                text: err
            });
        })

        
      }
    },
    
}
</script>
<style scoped>

</style>


