import Vue from "vue";
import Router from "vue-router";

import Dashboard from "@/components/Dashboard";
import Login from "@/components/Login";
import PaymentReport from "@/components/PaymentReport"
import HomePage from "@/components/HomePage"

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/home",
      name: "Home",
      component: HomePage
    },
    {
      path: "/reports",
      name: "Reports",
      component: PaymentReport
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard
    },
    {
      path: "/",
      name: "Login",
      component: Login
    }
  ]
});
