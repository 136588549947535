/* eslint-disable no-inner-declarations */

<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      fixed
      app
      class="blue darken-3"
      :width="220"
    >
      <v-list dense>
        <template v-for="item in items">
          <v-layout v-if="item.heading" :key="item.heading" row align-center>
            <v-flex xs6>
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-flex>
            <v-flex xs6 class="text-xs-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-flex>
          </v-layout>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title
                    class="white--text"
                    @click="SideMenuClick(item.text)"
                  >{{ item.text }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <v-list-tile v-for="(child, i) in item.children" :key="i" @click="SideMenuClick(child)">
              <v-list-tile-action v-if="child.icon">
                <v-icon class="white--text">{{ child.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title class="white--text">{{ child.text }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>
          <v-list-tile v-else :key="item.text">
            <v-list-tile-action>
              <v-icon class="white--text">{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.text }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
      <v-flex class="white--text" ma-2>
        <table width="200">
          <tr>
            <th>Allowed</th>
            <th>Limit Per</th>
            <th>Daily Limit</th>
          </tr>
          <tr>
            <td align="center" bgcolor="white">
              <img src="./logo.png" width="50" height="15" />
            </td>
            <td align="center">--</td>
            <td align="center">--</td>
          </tr>
          <tr>
            <td align="center" bgcolor="white">
              <img src="./ecocash.png" width="45" height="10" />
            </td>
            <td align="center">$500</td>
            <td align="center">--</td>
          </tr>
          <tr>
            <td align="center" bgcolor="white">
              <img src="./ecocashfca.png" width="45" height="15" />
            </td>
            <td align="center">$500</td>
            <td align="center">--</td>
          </tr>
          <tr>
            <td align="center" bgcolor="white">
              <img src="./telecash.png" width="45" height="15" />
            </td>
            <td align="center">$500</td>
            <td align="center">--</td>
          </tr>
          <tr>
            <td align="center" bgcolor="white">
              <img src="./onemoney.png" width="35" height="20" />
            </td>
            <td align="center">$500</td>
            <td align="center">--</td>
          </tr>
          <tr>
            <td align="center" bgcolor="white">
              <img src="./zipit.png" width="45" height="15" />
            </td>
            <td align="center">$10K</td>
            <td align="center">--</td>
          </tr>
          <tr>
            <td align="center" bgcolor="white">
              <img src="./visa.png" width="40" height="20" />
            </td>
            <td align="center">$500</td>
            <td align="center">--</td>
          </tr>
          <tr>
            <td align="center" bgcolor="white">
              <img src="./mastercard.png" width="40" height="25" />
            </td>
            <td align="center">$500</td>
            <td align="center">--</td>
          </tr>
        </table>
      </v-flex>
    </v-navigation-drawer>
    <v-toolbar :clipped-left="$vuetify.breakpoint.lgAndUp" class="gradient" dark app fixed flat>
      <v-toolbar-title class>
        <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      </v-toolbar-title>
      <img src="./logo.png" width="110" height="30" @click="page = 'home-page'" />

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="dialogConfirmOpen">
            <v-icon v-on="on">account_balance</v-icon>
          </v-btn>
        </template>
        <span>Change Centre</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="quicktransact">
            <v-icon v-on="on">add_shopping_cart</v-icon>
          </v-btn>
        </template>
        <span>Quick Transaction</span>
      </v-tooltip>

      <v-btn icon @click="approvals">
        <v-badge overlap color="red">
          <template v-slot:badge>
            <span>{{approvalsCounter}}</span>
          </template>
          <v-icon>notifications</v-icon>
        </v-badge>
      </v-btn>

      <v-menu>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon>
                <v-icon v-on="{ ...tooltip, ...menu }">apps</v-icon>
              </v-btn>
            </template>
            <span>User Menu</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-tile
            v-for="(item, index) in menuitems"
            :key="index"
            @click="selectedMenuItem(item.title)"
          >
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-content color="primary" class="pa-0">
      <v-progress-linear :indeterminate="true" v-if="progress"></v-progress-linear>
      <v-container fluid grid-list-md class="gradient3">
        <component
          v-bind:is="page"
          :PropData="propData"
          :PropCentre="centre_id"
          :PropCurrency="centreCurrency"
          :PropSymbol="propSymbol"
          :ProCharge="is_charged"
        ></component>

        <!-- chat bot widget -->
        <component v-bind:is="chatpage"></component>
      </v-container>
    </v-content>

    <!-- 
    Single Payment Request Form 
    -->
    <v-dialog v-model="requestSinglePayDialog" persistent max-width="500px">
      <v-tabs centered color="blue darken-4" icons-and-text>
        <v-tabs-slider color="red"></v-tabs-slider>

        <v-tab href="#tab-1">
          Charge
          <v-icon>card_giftcard</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          Disburse
          <v-icon>card_travel</v-icon>
        </v-tab>

        <v-tab-item :value="'tab-1'">
          <v-card>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6>
                    <v-select
                      v-model="requestObjCharge.provider"
                      :items="paymentRetortProviders"
                      label="Provider"
                      @input="filterCurrenciesByProvider(requestObjCharge.provider,'charge')"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-select
                      v-model="requestObjCharge.merchant"
                      :items="centreName"
                      label="Merchant Centre"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="requestObjCharge.account"
                      type="text"
                      label="Account"
                      hint="Enter your account number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="requestObjCharge.amount"
                      label="Amount"
                      hint="Enter the amount to charge"
                      type="number"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-select
                      v-model="requestObjCharge.currency"
                      :items="centreCurrencyArray"
                      label="Currency"
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="requestObjCharge.reference"
                      label="Reference"
                      hint="Please enter a Unique Reference"
                      type="text"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="requestObjCharge.description"
                      name="input-7-1"
                      label="Description"
                      value
                      hint="Please enter a description for you transaction"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="requestSinglePayDialog = false">Close</v-btn>
              <v-btn
                color="blue darken-1"
                :loading="loading"
                :disabled="loading"
                flat
                @click="requestSinglePayCharge"
              >Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>

        <v-tab-item :value="'tab-2'">
          <v-card>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6>
                    <v-select
                      v-model="requestObjDisburse.provider"
                      :items="paymentRetortProviders"
                      label="Provider"
                      @input="filterCurrenciesByProvider(requestObjDisburse.provider,'disburse')"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-select
                      v-model="requestObjDisburse.merchant"
                      :items="centreName"
                      label="Merchant Centre"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="requestObjDisburse.account"
                      type="text"
                      label="Account"
                      hint="Enter your account number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="requestObjDisburse.amount"
                      label="Amount"
                      hint="Enter the amount to charge"
                      type="text"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-select
                      v-model="requestObjDisburse.currency"
                      :items="centreCurrencyArray"
                      label="Currency"
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="requestObjDisburse.reference"
                      label="Reference"
                      hint="Please enter a Unique Reference"
                      type="text"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="requestObjDisburse.description"
                      name="input-7-1"
                      label="Description"
                      value
                      hint="Please enter a description for you transaction"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="requestSinglePayDialog = false">Close</v-btn>
              <v-btn
                color="blue darken-1"
                :loading="loading"
                :disabled="loading"
                flat
                @click="requestSinglePayDisbure"
              >Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
    <!-- End of payment form -->

    <!-- this is the initual dialog that prompts the user to select a centre -->
    <v-layout row justify-center>
      <v-dialog v-model="dialogConfirm" persistent width="400">
        <v-card>
          <v-card-title class="headline blue" primary-title>Please select centre</v-card-title>

          <v-card-text>
            <v-select
              item-text="center_name"
              item-value="centers"
              label="Centres"
              @input="getCurrencies"
              :items="centers"
            ></v-select>

            <v-select
              v-model="selectedCurrency"
              :items="graphCurrencies"
              item-text="tran_curr"
              item-value="tran_curr"
              label="Currency"
              @input="CentreIdFun"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- this is the initual dialog that prompts the user to select a centre -->
    <v-layout row justify-center>
      <v-dialog v-model="dialogRestPassword" persistent width="400">
        <v-card>
          <v-card-title class="headline blue" primary-title>Password Rest Form</v-card-title>

          <v-card-text>
            <v-flex xs12>
              <v-text-field
                v-model="restUserPassword.old"
                type="password"
                placeholder="Old Password"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="restUserPassword.new"
                type="password"
                placeholder="New Password"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="restUserPassword.confirm"
                type="password"
                placeholder="Confirm Password"
              />
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogRestPassword = false">Close</v-btn>
            <v-btn color="blue darken-1" text @click="RestPassword">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-app>
</template>

<script>
/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
/* eslint-disable no-unused-vars */
var moment = require("moment");
/* eslint-disable no-undef */
import { Observable } from "rxjs";

import PaymentSent from "./PaymentSent.vue";
import PaymentReceived from "./PaymentReceived.vue";
import PaymentReport from "./PaymentReport.vue";
import HomePage from "./HomePage.vue";
import UserPage from "./UserAdmin.vue";
import MerchantPage from "./MerchantAdmin.vue";
import CentreBalances from "./CentreBalances.vue";
import ClientBalances from "./ClientBalances.vue";
import CentersPage from "./CentersPage.vue";
import ClientsPage from "./ClientsPage.vue";
import ApprovalList from "./ApprovalList.vue";
import MulitDisburse from "./MulitDisburse.vue";
import ChatBot from "./ChatBot.vue";
import { Balloon } from "vue-balloon";
export default {
  created() {
    if (this.$session.exists()) {
      this.LoginedUser = this.$session.get("user");
      this.propData = this.LoginedUser;
      this.role = this.LoginedUser.role;
      this.menuitems[0].title = this.LoginedUser.username;
      this.chatpage = "chatbot-page";
      //console.log(this.role)
      //this.getCompanies(this.LoginedUser.id)
      this.backhome();
      this.getProviders();

      this.getCurrency();

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000
      });

      Toast.fire({
        type: "success",
        title: "Signed in successfully"
      });
      this.getUserApprovalActions(this.LoginedUser.approve_as);
      window.setInterval(() => {
        // eslint-disable-next-line
        this.getUserApprovalActions(this.LoginedUser.approve_as);
      }, 10000);
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  subscriptions: function() {
    return {
      msg: new Observable()
    };
  },
  computed: {
    formTitleUser() {
      return this.editedIndexUser === -1 ? "New User" : "Edit User";
    },
    formTitleMerchant() {
      return this.editedIndexMerchant === -1 ? "New Merchant" : "Edit Merchant";
    },
    items() {
      let items = [
        {
          icon: "dashboard",
          "icon-alt": "dashboard",
          text: "Dashboard",
          model: false,
          children: []
        },
        {
          icon: "assessment",
          "icon-alt": "agentreport",
          text: "Agent Report",
          model: false,
          children: [
            { icon: "trending_flat", text: "All Trancation" },
            { icon: "trending_flat", text: "Cash Ins" },
            { icon: "trending_flat", text: "Cash Outs" }
          ]
        },
        {
          icon: "store",
          "icon-alt": "agentoperations",
          text: "Agent Operations",
          model: false,
          children: [
            { icon: "trending_flat", text: "Cash In" },
            { icon: "trending_flat", text: "Cash Out" },
            { icon: "trending_flat", text: "Wallet Transaction" },
            { icon: "trending_flat", text: "Request settlement" }
          ]
        },
        {
          icon: "how_to_vote",
          "icon-alt": "how_to_vote",
          text: "Payouts",
          model: false,
          children: [
            { icon: "trending_flat", text: "Approvals" },
            { icon: "trending_flat", text: "Request Multiple" }
          ]
        },
        {
          icon: "settings",
          "icon-alt": "settings",
          text: "Configure",
          model: false,
          children: [
            { icon: "trending_flat", text: "Clients" },
            { icon: "trending_flat", text: "Centres" },
            { icon: "trending_flat", text: "Companies" },
            { icon: "trending_flat", text: "Users" }
          ]
        },
        {
          icon: "local_atm",
          "icon-alt": "local_atm",
          text: "Balances",
          model: false,
          children: [
            { icon: "trending_flat", text: "Client" },
            { icon: "trending_flat", text: "Centre" }
          ]
        },
        {
          icon: "local_atm",
          "icon-alt": "local_atm",
          text: "Value Added Service",
          model: false,
          children: [
            { icon: "trending_flat", text: "VAS Users" },
            { icon: "trending_flat", text: "VAS Centres" }
          ]
        }
      ];
      if (this.role == 1) {
        items = [
          {
            icon: "dashboard",
            "icon-alt": "dashboard",
            text: "Dashboard",
            model: false,
            children: []
          },
          {
            icon: "payment",
            "icon-alt": "payment",
            text: "Reports",
            model: false,
            children: [
              { icon: "trending_flat", text: "All Payments" },
              { icon: "trending_flat", text: "Payments Received" },
              { icon: "trending_flat", text: "Payments Sent" }
            ]
          },
          {
            icon: "how_to_vote",
            "icon-alt": "how_to_vote",
            text: "Payouts",
            model: false,
            children: [
              { icon: "trending_flat", text: "Approvals" },
              { icon: "trending_flat", text: "Request Multiple" }
            ]
          },
          {
            icon: "settings",
            "icon-alt": "settings",
            text: "Configure",
            model: false,
            children: [
              // { icon: 'trending_flat', text: 'Clients'},
              { icon: "trending_flat", text: "Centres" },
              { icon: "trending_flat", text: "Companies" },
              { icon: "trending_flat", text: "Users" }
            ]
          },
          {
            icon: "local_atm",
            "icon-alt": "local_atm",
            text: "Balances",
            model: false,
            children: [
              { icon: "trending_flat", text: "Client" },
              { icon: "trending_flat", text: "Centre" }
            ]
          }
        ];
      }
      if (this.role == 2) {
        items = [
          {
            icon: "dashboard",
            "icon-alt": "dashboard",
            text: "Dashboard",
            model: false,
            children: []
          },
          {
            icon: "payment",
            "icon-alt": "payment",
            text: "Reports",
            model: false,
            children: [
              { icon: "trending_flat", text: "All Payments" },
              { icon: "trending_flat", text: "Payments Received" },
              { icon: "trending_flat", text: "Payments Sent" }
            ]
          },
          {
            icon: "how_to_vote",
            "icon-alt": "how_to_vote",
            text: "Payouts",
            model: false,
            children: [
              { icon: "trending_flat", text: "Approvals" },
              { icon: "trending_flat", text: "Request Multiple" }
            ]
          },
          {
            icon: "local_atm",
            "icon-alt": "local_atm",
            text: "Balances",
            model: false,
            children: [{ icon: "trending_flat", text: "Centre" }]
          }
        ];
      }
      if (this.role == 3) {
        items = [
          {
            icon: "dashboard",
            "icon-alt": "dashboard",
            text: "Dashboard",
            model: false,
            children: []
          },
          {
            icon: "payment",
            "icon-alt": "payment",
            text: "Reports",
            model: false,
            children: [
              { icon: "trending_flat", text: "All Payments" },
              { icon: "trending_flat", text: "Payments Received" },
              { icon: "trending_flat", text: "Payments Sent" }
            ]
          },
          {
            icon: "how_to_vote",
            "icon-alt": "how_to_vote",
            text: "Payouts",
            model: false,
            children: [
              { icon: "trending_flat", text: "Approvals" },
              { icon: "trending_flat", text: "Request Multiple" }
            ]
          },
          {
            icon: "local_atm",
            "icon-alt": "local_atm",
            text: "Balances",
            model: false,
            children: [{ icon: "trending_flat", text: "Centre" }]
          }
        ];
      }
      if (this.role == 4) {
        items = [
          {
            icon: "dashboard",
            "icon-alt": "dashboard",
            text: "Dashboard",
            model: false,
            children: []
          },
          {
            icon: "payment",
            "icon-alt": "payment",
            text: "Reports",
            model: false,
            children: [
              { icon: "trending_flat", text: "All Payments" },
              { icon: "trending_flat", text: "Payments Received" },
              { icon: "trending_flat", text: "Payments Sent" }
            ]
          },
          {
            icon: "how_to_vote",
            "icon-alt": "how_to_vote",
            text: "Payouts",
            model: false,
            children: [
              { icon: "trending_flat", text: "Approvals" },
              { icon: "trending_flat", text: "Request Multiple" }
            ]
          }
        ];
      }
      if (this.role == 5) {
        items = [
          {
            icon: "dashboard",
            "icon-alt": "dashboard",
            text: "Dashboard",
            model: false,
            children: []
          },
          {
            icon: "payment",
            "icon-alt": "payment",
            text: "Agent Reports",
            model: false,
            children: [
              { icon: "trending_flat", text: "All Trancation" },
              { icon: "trending_flat", text: "Cash Ins" },
              { icon: "trending_flat", text: "Cash Outs" }
            ]
          },
          {
            icon: "how_to_vote",
            "icon-alt": "how_to_vote",
            text: "Agent Operations",
            model: false,
            children: [
              { icon: "trending_flat", text: "Cash In" },
              { icon: "trending_flat", text: "Cash Out" },
              { icon: "trending_flat", text: "Wallet Transaction" },
              { icon: "trending_flat", text: "Request settlement" }
            ]
          }
        ];
      }

      return items;
    }
  },
  watch: {
    selectedCurrency(val) {
      //console.log(val)
    },
    dialogConfirm(val) {
      //console.log(val)
    },
    dialogUser(val) {
      val || this.closeUser();
    },
    dialogMerchant(val) {
      val || this.closeMerchant();
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    }
  },
  components: {
    "home-page": HomePage,
    "payment-report": PaymentReport,
    "sent-report": PaymentSent,
    "received-report": PaymentReceived,
    "merchant-page": MerchantPage,
    "client-page": ClientsPage,
    "center-page": CentersPage,
    "centerbal-page": CentreBalances,
    "clientbal-page": ClientBalances,
    "user-page": UserPage,
    "approval-page": ApprovalList,
    "multi-disburse": MulitDisburse,
    "chatbot-page": ChatBot,
    Balloon
  },
  filters: {
    changeDateFilter: function(value) {
      return moment(value).format("MMMM Do YYYY, h:mm:ss a");
    }
  },
  data: () => ({
    role: 0,
    centre_id: 0,
    centreName: [],
    centreCurrencyArray: [],
    restUserPassword: {
      old: "",
      new: "",
      confirm: ""
    },
    dialogRestPassword: false,
    timer: "",
    tran_id: "",
    userPassReset: {
      password: "",
      api_token: ""
    },
    dialogConfirm: false,
    privateKey: "",
    phpService: "/service/service?endPoint=",
    nodeApi: "https://serv.contitouch.co.zw/",
    checksumAPI: "https://dash-dev.contipay.co.zw/",
    //checksumAPI: 'http://localhost/',
    //nodeApi: 'http://localhost:81/',
    //payment transaction object
    //contipayApiPay:'http://localhost:8080/request/rest',
    contipayApiPay: "https://api-dev.contipay.co.zw/request/rest",
    postTransaction: {
      authKey: "K2NlMlZQTTJtREkzczdZeEJkRUQxZz09",
      authPass: "Hsat@563",
      returnUrl: "",
      checkSum: "",
      timestamp: "",
      transaction: {
        method: "",
        type: "",
        account: "",
        amount: "",
        reference: "",
        description: "",
        center: "22",
        firstName: "",
        lastName: "",
        email: "",
        code: null,
        currency: "",
        expiry: null,
        completeUrl: ""
      }
    },
    menuitems: [
      { title: "" },
      { title: "Reset Password" },
      { title: "Logout" }
    ],
    //navImage: require('./nav.fw.png'),
    //data variable for requestSinglePay dialog
    requestObjCharge: {
      provider: "",
      merchant: "",
      currency: "",
      account: "",
      amount: 0,
      reference: "",
      description: "",
      method: "",
      type: ""
    },
    requestObjDisburse: {
      provider: "",
      merchant: "",
      currency: "",
      account: "",
      amount: 0,
      reference: "",
      description: "",
      method: "",
      type: ""
    },
    //Page componnent
    propData: [],
    propSymbol: "",
    page: "",
    chatpage: "",
    progress: false,
    currencySymbol: "",

    //Payment Reports Data information
    filterData: {
      dateFrom: "",
      dataTo: "",
      provider: "",
      currency: "",
      status: "",
      type: ""
    },
    hide: false,
    datesFilters: [],
    selectedCurrency: [],
    menu: false,
    moment: moment,
    reportData: {},
    search: "",
    searchType: "",
    //multi search variable
    filters: {
      search: "",
      added_by: ""
    },

    searchProvider: "",
    searchCurrency: "",
    expand: false,
    headers: [
      {
        text: "Centre Name",
        align: "center",
        value: "center_name"
      }
    ],
    headersPayments: [
      { text: "Created Date", value: "created_date" },
      { text: "Amount", value: "amount" },
      { text: "Currency", value: "currency" },
      { text: "Merchant", value: "merchant" },
      { text: "Provider", value: "provider" },
      { text: "Status", value: "status" }
    ],
    loader: null,
    loading: false,
    transactionMessage: "",
    paymentRetortData: [],
    is_charged: null,
    paymentReportDataFilter: [],
    paymentReportCurrencies: [],
    paymentReportCompanies: [],
    paymentRetortProviders: [],
    paymentRetortStatus: [],
    graphCurrencies: [],
    selectedProvider: [],
    selectedCompanies: [],
    selectedType: ["Charge", "Disburse"],
    usersRetortCompanies: [],
    centreID: [],
    centers: [],
    centreCurrency: "",

    //end of payment reports form

    paymentStatus: 3,
    paymentsFormDialog: false,
    requestSinglePayDialog: false,
    merchantAdminDialog: false,
    userAdminDialog: false,
    LoginedUser: {},
    dialogUser: false,
    dialogMerchant: false,
    drawer: null,
    lorem: `Lorem ipsum dolor sit amet, mel at clita quando. Te sit oratio vituperatoribus, nam ad ipsum posidonium mediocritatem, explicari dissentiunt cu mea. Repudiare disputationi vim in, mollis iriure nec cu, alienum argumentum ius ad. Pri eu justo aeque torquatos.`,

    //The are the left menu Items

    UserApprovalActions: [],
    approvalsCounter: 0,
    approvalActionList: []
  }),
  props: {
    source: String
  },

  methods: {
    async RestPassword() {
      //console.log(this.restUserPassword)
      var headers = {
        "Content-Type": "application/json",
        Authorisation:
          "SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=",
        timestamp: "1556893117"
      };

      let hashpass = "";

      if (this.restUserPassword.new == this.restUserPassword.confirm) {
        await this.axios
          .post(
            this.phpService + "?endPoint=request/service/getToken",
            { string: this.restUserPassword.old },
            { headers: headers }
          )
          .then(function(response) {
            // handle success
            hashpass = response.data.message;
            //console.log(response);
          })
          .catch(function(error) {
            // handle error
            // console.log(error);
          });

        await this.axios
          .post(this.nodeApi + "getUserIdByPassword", { pass: hashpass })
          .then(res => {
            if (res.data.length > 0) {
              let username = res.data[0].username;
              let password = this.restUserPassword.new;
              let user_id = res.data[0].id;
              this.resetPassword(username, password, user_id);
            } else {
              Swal.fire({
                type: "error",
                title: "Password Reset!!",
                text: "Old password is wrong please try again"
              });
            }
          })
          .catch(err => {
            Swal.fire({
              type: "error",
              title: "Password Reset!!",
              text: err
            });
          });
      } else {
        Swal.fire({
          type: "error",
          title: "Password Reset!!",
          text: "You confirm password is different from the new password!!"
        });
      }
    },
    async resetPassword(username, password, user_id) {
      var headers = {
        "Content-Type": "application/json",
        Authorisation:
          "SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=",
        timestamp: "1556893117"
      };

      let hashusernamepassward = "";
      let hashuser = "";
      let hashpass = "";
      let usernameData = {
        string: username
      };
      let passData = {
        string: password
      };

      await this.axios
        .post(this.phpService + "?endPoint=request/service/getToken", {
          string: username
        })
        .then(function(response) {
          // handle success
          hashuser = response.data.message;
          //console.log(response);
        })
        .catch(function(error) {
          // handle error
          // console.log(error);
        });
      //console.log(hashuser);
      await this.axios
        .post(this.phpService + "?endPoint=request/service/getToken", {
          string: password
        })
        .then(function(response) {
          // handle success
          hashpass = response.data.message;
          //console.log(response);
        })
        .catch(function(error) {
          // handle error
          // console.log(error);
        });
      let usernamepassData = {
        string: hashuser + hashpass
      };
      //console.log(hashpass);
      await this.axios
        .post(this.phpService + "?endPoint=request/service/getToken", {
          string: hashuser + password
        })
        .then(function(response) {
          // handle success
          hashusernamepassward = response.data.message;
          //console.log(response);
        })
        .catch(function(error) {
          // handle error
          // console.log(error);
        });
      // console.log(hashusernamepassward);
      this.userPassReset.api_token = hashusernamepassward;
      this.userPassReset.password = hashpass;

      await this.axios
        .put(this.nodeApi + "updatePassword/" + user_id, {
          details: this.userPassReset
        })
        .then(res => {
          //console.log(res.data)
          if (res.data.affectedRows) {
            this.dialogRestPassword = false;
            Swal.fire({
              type: "success",
              title: "Password Reset!!",
              text: "Your password been resest successfully.",
              showConfirmButton: true
            });
          } else {
            Swal.fire({
              type: "error",
              title: "Password Reset!!",
              text: res.data.sqlMessage
            });
          }
        })
        .catch(err => {
          Swal.fire({
            type: "error",
            title: "Password Reset!!",
            text: err
          });
        });
    },
    selectedMenuItem(menu) {
      if (menu == "Logout") {
        this.logout();
      }
      if (menu == "Reset Password") {
        //this.logout()
        this.dialogRestPassword = true;
      }
    },

    dialogConfirmOpen() {
      this.page = "";
      this.dialogConfirm = true;
    },

    getUserApprovalActions(data) {
      this.axios
        .post(this.nodeApi + "getUserActionByLevel", { element1: data })
        .then(res => {
          this.UserApprovalActions = res.data;
          this.axios
            .post(this.nodeApi + "getApprovalMerchantTransactionsMulti", {
              centre_id: this.centre_id,
              currency: this.centreCurrency,
              action_from: this.UserApprovalActions[0].From,
              user_id: this.LoginedUser.id
            })
            .then(res => {
              this.approvalsCounter = res.data.length;

              //console.log(res.data)
            })
            .catch(err => {
              Swal.fire({
                type: "error",
                title: "Approval action Error!!",
                text: err
              });
            });
          //console.log(this.UserApprovalActions)
        })
        .catch(err => {
          Swal.fire({
            type: "error",
            title: "Approval action Error!!",
            text: err
          });
        });
    },

    //Function for posting payment charge
    requestSinglePayCharge() {
      //console.log(this.LoginedUser)
      this.loading = true;
      this.requestObjCharge.type = "charge";
      this.postTransaction.email = this.LoginedUser.email;
      this.postTransaction.transaction.method = this.requestObjCharge.provider;
      this.postTransaction.transaction.type = this.requestObjCharge.type;
      this.postTransaction.transaction.account = this.requestObjCharge.account;
      this.postTransaction.transaction.amount = this.requestObjCharge.amount;
      this.postTransaction.transaction.reference = this.requestObjCharge.reference;
      this.postTransaction.transaction.description = this.requestObjCharge.description;
      this.postTransaction.transaction.currency = this.requestObjCharge.currency;
      this.postTransaction.transaction.center = this.centre_id;
      this.postTransaction.authPass = btoa("Hsat@563");
      //this.postTransaction.transaction.firstName = this.LoginedUser.first_name
      //this.postTransaction.transaction.lastName = this.LoginUser.last_name

      //var form = new FormData();
      this.postTransaction.timestamp = Math.floor(Date.now() / 1000);

      let rawchecksum =
        this.postTransaction.authKey +
        this.postTransaction.authPass +
        this.postTransaction.timestamp +
        this.postTransaction.transaction.center +
        this.postTransaction.transaction.account +
        this.postTransaction.transaction.amount;
      //form.append('data', rawchecksum);
      // console.log(rawchecksum)

      this.axios
        .post("sign", { data: rawchecksum })
        .then(res => {
          var headers = {
            "Content-Type": "application/json",
            authKey: "K2NlMlZQTTJtREkzczdZeEJkRUQxZz09",
            authPass: this.postTransaction.authPass,
            checksum: res.data
          };

          this.postTransaction.checkSum = res.data;

          //this.loading = false
          this.axios
            .post(
              this.contipayApiPay,
              {
                authKey: this.postTransaction.authKey,
                authPass: this.postTransaction.authPass,
                returnUrl: this.postTransaction.returnUrl,
                checkSum: this.postTransaction.checkSum,
                timestamp: this.postTransaction.timestamp,
                transaction: this.postTransaction.transaction
              },
              { headers: headers }
            )
            .then(res => {
              if (res.data.contiPayRef) {
                this.paymentsFormDialog = false;
                this.transactionMessage = res.data.message;
                //this.dialogConfirm = true
                let data = res.data.contiPayRef;
                //console.log(res.data.data)
                let interval = window.setInterval(() => {
                  this.axios
                    .post(this.nodeApi + "getTransactionsStatus", {
                      tran_id: data
                    })
                    .then(res => {
                      if (res.data[0].status == 1) {
                        myStopFunction();
                      } else if (res.data[0].status == 4) {
                        myStopFunction2();
                      }
                    })
                    .catch(err => {
                      Swal.fire({
                        type: "error",
                        title: "Transaction Error!!",
                        text: err
                      });
                    });
                }, 1000);

                // eslint-disable-next-line no-inner-declarations
                function myStopFunction() {
                  clearInterval(interval);
                  Swal.fire({
                    type: "success",
                    title: "Transaction Confirmed!!",
                    text: "The client has confirmed the transaction",
                    showConfirmButton: true
                  });
                }
                // eslint-disable-next-line no-inner-declarations
                function myStopFunction2() {
                  clearInterval(interval);
                  Swal.fire({
                    type: "error",
                    title: "Transaction delined !!",
                    text: "Transaction was delined by client!!"
                  });
                }

                Swal.fire({
                  type: "success",
                  title: "Transaction Pending : ID " + res.data.contiPayRef,
                  text: res.data.message,
                  onBeforeOpen: () => {
                    Swal.showLoading();
                  }
                });

                this.loading = false;
              } else {
                Swal.fire({
                  timer: 10000,
                  type: "error",
                  title: "Transaction Error!!",
                  text: res.data.message,
                  showConfirmButton: false
                });
                this.loading = false;
                //this.requestSinglePayDialog = false
              }
            })
            .catch(err => {
              this.loading = false;
              Swal.fire({
                type: "error",
                title: "Transaction Error!!",
                text: err
              });
            });

          this.requestObjCharge = {};
        })
        .catch(err => {
          Swal.fire({
            type: "error",
            title: "CheckSum Error!!",
            text: err
          });
          this.loading = false;
        });
    },

    //Function for posting payment disburse
    requestSinglePayDisbure() {
      this.loading = true;
      this.requestObjDisburse.type = "disburse";
      this.postTransaction.email = this.LoginedUser.email;
      this.postTransaction.transaction.method = this.requestObjDisburse.provider;
      this.postTransaction.transaction.type = this.requestObjDisburse.type;
      this.postTransaction.transaction.account = this.requestObjDisburse.account;
      this.postTransaction.transaction.amount = this.requestObjDisburse.amount;
      this.postTransaction.transaction.reference = this.requestObjDisburse.reference;
      this.postTransaction.transaction.description = this.requestObjDisburse.description;
      this.postTransaction.transaction.currency = this.requestObjDisburse.currency;
      this.postTransaction.transaction.center = this.centre_id;
      this.postTransaction.authPass = btoa("Hsat@563");

      this.postTransaction.timestamp = Math.floor(Date.now() / 1000);

      let rawchecksum =
        this.postTransaction.authKey +
        this.postTransaction.authPass +
        this.postTransaction.timestamp +
        this.postTransaction.transaction.center +
        this.postTransaction.transaction.account +
        this.postTransaction.transaction.amount;

      this.axios
        .post("sign", { data: rawchecksum })
        .then(res => {
          var headers = {
            "Content-Type": "application/json",
            authKey: "K2NlMlZQTTJtREkzczdZeEJkRUQxZz09",
            authPass: this.postTransaction.authPass,
            checksum: res.data,
            timestamp: this.postTransaction.timestamp
          };

          this.postTransaction.checkSum = res.data;

          //this.loading = false
          this.axios
            .post(
              this.contipayApiPay,
              {
                authKey: this.postTransaction.authKey,
                authPass: this.postTransaction.authPass,
                returnUrl: this.postTransaction.returnUrl,
                checkSum: this.postTransaction.checkSum,
                timestamp: this.postTransaction.timestamp,
                transaction: this.postTransaction.transaction
              },
              { headers: headers }
            )
            .then(res => {
              if (es.data.contiPayRef) {
                Swal.fire({
                  type: "success",
                  title: "Transaction " + res.data.status,
                  text: res.data.message
                });
                this.loading = false;
              } else {
                Swal.fire({
                  position: "top-end",
                  type: "success",
                  title: "Your transaction has been saved",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.loading = false;
                this.requestSinglePayDialog = false;
              }
            })
            .catch(err => {
              this.loading = false;
              Swal.fire({
                type: "error",
                title: "Transaction Error!!",
                text: err
              });
            });

          this.requestObjDisburse = {};
        })
        .catch(err => {
          Swal.fire({
            type: "error",
            title: "CheckSum Error!!",
            text: err
          });
          this.loading = false;
        });
    },

    //filter for what currency a provider offers
    filterCurrenciesByProvider(provider, chargeType) {
      this.paymentReportDataFilter = [];
      if (chargeType == "charge") {
        this.paymentReportCurrencies.forEach(element => {
          if (provider == element.name) {
            this.paymentReportDataFilter.push(element.charge_code);
          }
        });
      } else if (chargeType == "disburse") {
        this.paymentReportCurrencies.forEach(element => {
          if (provider == element.name) {
            this.paymentReportDataFilter.push(element.disburse_code);
          }
        });
      }
    },

    //Payment
    getCurrency() {
      this.axios
        .get(this.nodeApi + "getProviderCurrencies")
        .then(data => {
          data.data.forEach(element => {
            this.paymentReportCurrencies.push(element);
          });

          this.paymentReportDataFilter = this.paymentReportCurrencies;
        })
        .catch(err => {
          Swal.fire({
            type: "error",
            title: "Can not load reports!!",
            text: err
          });
        });
    },
    getProviders() {
      this.axios
        .get(this.nodeApi + "getProviders")
        .then(data => {
          data.data.forEach(element => {
            this.paymentRetortProviders.push(element.name);
          });
        })
        .catch(err => {
          Swal.fire({
            type: "error",
            title: "Can not load reports!!",
            text: err
          });
        });
    },
    async CentreIdFun(val) {
      //console.log(this.selectedCurrency)
      let currencySymbal = "";
      await this.axios
        .post(this.nodeApi + "getCurrenciesBySymbal", {
          currency: this.selectedCurrency
        })
        .then(res => {
          currencySymbal = res.data[0].symbol;
        })
        .catch(err => {});
      this.currencySymbol = currencySymbal;
      // this.centreName.push(this.centers.filter(this.centre_id))
      let centrefilter = [];
      centrefilter = this.centers.filter(obj => {
        return obj.centers === this.centre_id;
      });
      this.centreName = [];
      this.centreName.push(centrefilter[0].center_name);
      //console.log(this.centreName)
      this.centreCurrencyArray = [];
      this.centreCurrency = val;
      this.centreCurrencyArray.push(this.centreCurrency);

      await this.axios
        .post(this.nodeApi + "getCentreChargeByID_Currency", {
          centre_id: this.centre_id,
          currency: this.centreCurrency
        })
        .then(res => {
          this.is_charged = res.data[0].is_charged;
        })
        .catch(err => {});
      this.dialogConfirm = false;
      this.page = "home-page";
      //this.backhome()
    },
    getCurrencies(data) {
      this.centre_id = data;

      this.axios
        .post(this.nodeApi + "getCurrenciesByCentreID", { centre_id: data })
        .then(res => {
          let newjson = JSON.parse(res.data[0].currencies);
          let result = [];
          for (var i in newjson) {
            result.push({ id: newjson[i], tran_curr: newjson[i] });
          }

          this.graphCurrencies = result;
          this.selected = res.data[0].tran_curr;
          //
        })
        .catch(err => {});
    },
    getCompanies(data) {
      this.axios
        .post(this.nodeApi + "getCenterByID", { element1: data })
        .then(data => {
          //console.log(data)
          this.centers = data.data;

          data.data.forEach(element => {
            this.paymentReportCompanies.push(element.center_name);
            this.centreID.push(element.centers);
          });
          this.dialogConfirm = true;
          //this.backhome()
          //console.log(this.centreID)
          // console.log(data)
        })
        .catch(err => {
          Swal.fire({
            type: "error",
            title: "Can not load reports!!",
            text: err
          });
        });
    },
    getStatus(id, status) {
      let newstatus = "";
      this.paymentRetortStatus.forEach(element => {
        if (element.method_id == id && element.value == status) {
          newstatus = element.name;
        }
      });
      return newstatus;
    },
    logout() {
      this.$session.destroy();

      this.$router.push("/");
    },
    backhome() {
      this.propData = this.LoginedUser;
      this.getCompanies(this.LoginedUser.id);
    },
    quicktransact() {
      this.requestSinglePayDialog = true;
    },
    approvals() {
      this.propData = this.LoginedUser;
      this.page = "approval-page";
    },

    //Side menu functions
    SideMenuClick(clickedMenuItem) {
      if (clickedMenuItem.text == "All Payments") {
        this.progress = true;
        this.axios
          .post(this.nodeApi + "getPaymentMerchantTransactions", {
            element1: this.centre_id,
            element2: this.centreCurrency
          })
          .then(res => {
            this.progress = false;
            this.propData = res.data;
            this.propSymbol = this.currencySymbol;

            this.page = "payment-report";
          })
          .catch(err => {
            this.progress = false;
            Swal.fire({
              type: "error",
              title: "Can not load reports!!",
              text: err
            });
          });
      }
      if (clickedMenuItem.text == "Payments Sent") {
        this.progress = true;
        this.axios
          .post(this.nodeApi + "getPaymentMerchantTransactionsDisburse", {
            element1: this.centre_id,
            element2: this.centreCurrency
          })
          .then(res => {
            //console.log(res.data)
            this.progress = false;
            this.propData = res.data;
            this.propSymbol = this.currencySymbol;

            this.page = "sent-report";
          })
          .catch(err => {
            this.progress = false;
            Swal.fire({
              type: "error",
              title: "Can not load reports!!",
              text: err
            });
          });
      }
      if (clickedMenuItem.text == "Payments Received") {
        this.progress = true;
        this.axios
          .post(this.nodeApi + "getPaymentMerchantTransactionsCharge", {
            element1: this.centre_id,
            element2: this.centreCurrency
          })
          .then(res => {
            this.progress = false;
            this.propData = res.data;
            this.propSymbol = this.currencySymbol;

            this.page = "received-report";
          })
          .catch(err => {
            this.progress = false;
            Swal.fire({
              type: "error",
              title: "Can not load reports!!",
              text: err
            });
          });
      }
      if (clickedMenuItem.text == "Approvals") {
        this.propData = this.$route.params.userdata;

        this.page = "approval-page";
      }
      if (clickedMenuItem.text == "Request Multiple") {
        this.page = "multi-disburse";
      }
      if (clickedMenuItem.text == "Centres") {
        this.page = "center-page";
      }
      if (clickedMenuItem.text == "Centre") {
        this.page = "centerbal-page";
      }
      if (clickedMenuItem.text == "Clients") {
        this.page = "client-page";
      }
      if (clickedMenuItem.text == "Client") {
        this.page = "clientbal-page";
      }
      if (clickedMenuItem == "Dashboard") {
        this.page = "home-page";
      }
      if (clickedMenuItem.text == "Request Single") {
        this.requestSinglePayDialog = true;
      }
      if (clickedMenuItem.text == "Companies") {
        this.page = "merchant-page";
        this.propData = this.LoginedUser;
      }
      if (clickedMenuItem.text == "Users") {
        this.page = "user-page";
        this.propData = this.LoginedUser;
      }
    }
  }
};
</script>

<style>
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 5px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border-collapse: collapse;
}
tr {
  border: solid thin rgb(211, 211, 211);
}
td {
  border: solid thin rgb(211, 211, 211);
}
th {
  border: solid thin rgb(211, 211, 211);
  font-family: "Trebuchet MS", Arial, Verdana;
  font-size: 12px;
}

.gradient {
  background-image: url("bg.jpg");

  background-repeat: no-repeat;
  background-size: cover;

  /*background-image: linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%);*/
}
.gradient2 {
  /*
  background-image: url("bg.jpg");
  
  background-repeat: no-repeat;
  background-size: cover;
  */
  background-image: url("nv.fw.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.gradient3 {
}

.v-list__group__header__prepend-icon .v-icon {
  color: rgb(233, 244, 250);
}

.echarts {
  width: 99%;
  height: 400px;
  border-left: 5px solid #0145ff;
}
.card {
  height: 75px;
}
.cardRev {
  border-left: 5px solid #23d5f5;
}
.cardExp {
  border-left: 5px solid #fc5959;
}
.cardPro {
  border-left: 5px solid #374874;
}
.card2 {
  height: 400px;
  border-left: 5px solid #ff0101;
}

.h4Rev {
  color: #23d5f5;
}
.h4Exp {
  color: #fc5959;
}
.h4Pro {
  color: #374874;
}

#lateral .v-btn--floating {
  position: absolute;
}
#lateral .v-btn--floating {
  margin: 0 0 16px 16px;
}
</style>
