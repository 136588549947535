<template>
  
		<v-card class="chat-box">
			<div class="chat-head">
				<h4>Contipay Support</h4>
				<img src="https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png" title="Expand Arrow" width="16">
			</div>
			<div id="container" class="chat-body">
				<div class="msg-insert" v-for="message in currentChat" :key="message.time">
					<div  v-html="message.bubble">
					
					</div>
				</div>
				<div class="chat-text">
					<textarea v-model="clientMessage" placeholder="Send" id="texttype" @keyup="sendMessage"></textarea>
				</div>
			</div>
			
		</v-card>
	
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
import './chatbot.scss'
import * as jsPDF from 'jspdf'
import * as autoTable from 'jspdf-autotable'
export default {
  name: 'app',
  data() {
    return {
		client: '',
		clientMessage: '',
		contipayMessage: '',
		currentChat : [],
		thoughtData: '0',
		role: '',
		userCreation: {
			userEmail: '',
			userCell: ''
		},
		pdfChat: [],
		currentTime: '',
		nodeApi: 'https://serv.contitouch.co.zw/',
         // nodeApi: 'http://localhost:81/',
      
    }
  },
  updated: function () {
	//scroll down logic here
	var container = this.$el.querySelector("#container");
	container.scrollTop = container.scrollHeight;
  },
  watch: {
	  clientMessage(val){
		  //console.log(val.length)
	  },
	  currentChat(val){
		  //console.log(val)
	  },
	  thoughtData(val){
		  // eslint-disable-next-line no-console
		  console.log(val)
	  }
  },
  mounted() {
	this.client = this.$session.get('user').username;
	this.role = this.$session.get('user').role;
	//console.log(this.role)
	this.startMessage();
		  // eslint-disable-next-line no-undef
   $(function(){
		  // eslint-disable-next-line no-undef
	var arrow = $('.chat-head img');
	
	arrow.on('click', function(){
			var src = arrow.attr('src');
		  // eslint-disable-next-line no-undef
			$('.chat-body').slideToggle('fast');
			if(src == 'https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png'){
				arrow.attr('src', 'https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png');
				
			}
			else{
				arrow.attr('src', 'https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png');
				
			}
		});	
			

	
});
  },
  methods: {
	  getNowTime(){
		  // eslint-disable-next-line no-undef
		  this.currentTime = moment('h:mm a');    
	  },
	  startMessage(){
		  //var d = new Date();
		  var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		  var first_name =this.$session.get('user').first_name
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">Good ${first_name}, how can i help you today. Or use the below menu.</p>
					<p class="name">1. User Admin</p>
					<p class="name">2. Centre Admin</p>
					<p class="name">3. Company Admin</p>
					<p class="name">4. Promotion Admin</p>
					<p class="name">5. Settlement Admin</p>
					<p class="name">6. Export Chat</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			this.pdfChat.push({time:new Date().toLocaleTimeString(),bubble:`Good ${first_name}, how can i help you today. Or use the below menu.`,user:'ContiPay'})
			this.contipayMessage = ''
	  },
	  sendContiPayMessage(message){
		  //var d = new Date();
		  var first_name =this.$session.get('user').first_name
		  var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		 
		  if(message != undefined){
			
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">${message}</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			this.pdfChat.push({time:new Date().toLocaleTimeString(),bubble:message,user:'ContiPay'})
		  }else{
		 
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">Sorry!! ${first_name}, I didn't understand you question. Could you rephrase it for me please.</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			this.pdfChat.push({time:new Date().toLocaleTimeString(),bubble:message,user:'ContiPay'})
		  }
		 
			
	  },
	  sendClientMessage(message){
		  //var d = new Date();
		  var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		  var first_name =this.$session.get('user').first_name
		  this.clientMessage = `
			<div class="bubbleclient bounceIn">
				<div class="txt2">
					<p class="name2">${first_name}</p>
					<p class="message2">${message}</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow2"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.clientMessage})
			this.pdfChat.push({time:new Date().toLocaleTimeString(),bubble:message,user:`${first_name}`})
			//this.clientMessage = ''
	  },
	  exportChatToPdf(){
		  //console.log(this.thoughtData)
	   if(this.thoughtData == '061'){

	     
		var vm = this
        var columns = [
         {
          title: 'Time',
         
          dataKey: 'time'
        },
		{
          title: 'User',
         
          dataKey: 'user'
        },
        {
          title: 'Message',
         
          dataKey: 'bubble'
		}
        ]
        var doc = new jsPDF('l','pt')
        var totalPagesExp = "{total_pages_count_string}";

        doc.setTextColor(100);
        // doc.text('Contipay Merchant Report', 10, 20);
        //doc.autoTable(columns,vm.rows)
        doc.autoTable(
        columns, 
		vm.pdfChat,
		
        {
		
        didDrawPage: function (data) {
            // Header
            doc.setFontSize(20);
            doc.setTextColor(40);
            doc.setFontStyle('normal');
            
            doc.text("Contipay Support Chat", data.settings.margin.left + 15, 22);

            // Footer
            var str = "Page " + doc.internal.getNumberOfPages()
            // Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + "                                                                                                                                                                                                                                 @countitouch 2019";
            }
			doc.setFontSize(10);
			

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
				  margin: {top: 30},
				  columnStyles: { bubble: {cellWidth: 260}}
              });

              // Total page number plugin only available in jspdf v1.0+
              if (typeof doc.putTotalPages === 'function') {
                  doc.putTotalPages(totalPagesExp);
              }
		   doc.save('ContipaySupportChat.pdf')
		   this.currentChat = []
		   this.pdfChat = []
		   this.thoughtData = '0'
		   this.menu()
	   }else if(this.thoughtData == '06'){
		   //console.log(this.thoughtData)
		   this.confirmpdfExport()
	   }
	  },
	  confirmpdfExport(){
		var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		  
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">Are you sure you want to end this chat and export it as pdf?</p>
					<p class="name">1. Yes</p>
					<p class="name">2. No</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			//this.thoughtData += '1'
			this.contipayMessage = ''
			//this.exportChatToPdf()
			
	  },
	  menu(){
		  var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		  
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">Main Menu.</p>
					<p class="name">1. User Admin</p>
					<p class="name">2. Centre Admin</p>
					<p class="name">3. Company Admin</p>
					<p class="name">4. Promotion Admin</p>
					<p class="name">5. Settlement Admin</p>
					<p class="name">6. Export Chat</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			this.contipayMessage = ''
	  },
	  menu1(){
		  var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		  
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">User admin sub menu.</p>
					<p class="name">1. Add User</p>
					<p class="name">2. Disable User</p>
					<p class="name">3. Change User Role</p>
					<p class="name">4. Change User Level</p>
					<p class="name">0. Back to Menu</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			this.contipayMessage = ''
	  },
	  			     menu11(){
						var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
					   if(this.role < 4){
						
						this.contipayMessage = `
							<div class="bubblecontipay bounceIn">
								<div class="txt">
									<p class="name">ContiPay</p>
									<p class="message">Please enter new user email address.</p>
									<p class="name">0. Back to Menu</p>
									<span class="timestamp">${time}</span>
								</div>
								<div class="bubble-arrow"></div>
							</div>	
							`
							this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
							this.contipayMessage = ''

					   }else{
							var first_name =this.$session.get('user').first_name
							this.contipayMessage = `
								<div class="bubblecontipay bounceIn">
									<div class="txt">
										<p class="name">ContiPay</p>
										<p class="message">Sorry!! ${first_name} your account is not authorised to do this request.</p>
										<span class="timestamp">${time}</span>
									</div>
									<div class="bubble-arrow"></div>
								</div>	
								`
								this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
								this.thoughtData = '0'
								this.contipayMessage = ''
								setTimeout(() => this.menu(), 3000);

					   }
					 },
					 menu12(){
						var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
						
						this.contipayMessage = `
							<div class="bubblecontipay bounceIn">
								<div class="txt">
									<p class="name">ContiPay</p>
									<p class="message">User admin sub menu.</p>
									<p class="name">1. Add User</p>
									<p class="name">2. Disable User</p>
									<p class="name">3. Change User Role</p>
									<p class="name">4. Change User Level</p>
									<p class="name">0. Back to Menu</p>
									<span class="timestamp">${time}</span>
								</div>
								<div class="bubble-arrow"></div>
							</div>	
							`
							this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
							this.contipayMessage = ''
					},
					 menu13(){
						var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
						
						this.contipayMessage = `
							<div class="bubblecontipay bounceIn">
								<div class="txt">
									<p class="name">ContiPay</p>
									<p class="message">User admin sub menu.</p>
									<p class="name">1. Add User</p>
									<p class="name">2. Disable User</p>
									<p class="name">3. Change User Role</p>
									<p class="name">4. Change User Level</p>
									<p class="name">0. Back to Menu</p>
									<span class="timestamp">${time}</span>
								</div>
								<div class="bubble-arrow"></div>
							</div>	
							`
							this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
							this.contipayMessage = ''
					},
					 menu14(){
						var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
						
						this.contipayMessage = `
							<div class="bubblecontipay bounceIn">
								<div class="txt">
									<p class="name">ContiPay</p>
									<p class="message">User admin sub menu.</p>
									<p class="name">1. Add User</p>
									<p class="name">2. Disable User</p>
									<p class="name">3. Change User Role</p>
									<p class="name">4. Change User Level</p>
									<p class="name">0. Back to Menu</p>
									<span class="timestamp">${time}</span>
								</div>
								<div class="bubble-arrow"></div>
							</div>	
							`
							this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
							this.contipayMessage = ''
					},
	   menu2(){
		  var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		  
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">Centre admin sub menu.</p>
					<p class="name">1. Add Centre</p>
					<p class="name">2. Disable Centre</p>
					<p class="name">3. Add User to Centre</p>
					<p class="name">4. Remove User from Centre</p>
					<p class="name">0. Back to Menu</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			this.contipayMessage = ''
	  },
	   menu3(){
		  var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		  
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">Company admin sub menu.</p>
					<p class="name">1. Add New Bank Acc</p>
					<p class="name">2. Change Ecocash number</p>
					<p class="name">0. Back to Menu</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			this.contipayMessage = ''
	  },
	  menu4(){
		  var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		  
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">Promotion admin sub menu.</p>
					<p class="name">1. Add New Promotion</p>
					<p class="name">2. How many</p>
					<p class="name">3. Add User to Promo</p>
					<p class="name">4. Remove User from Promo</p>
					<p class="name">0. Back to Menu</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			this.contipayMessage = ''
	  },
	  menu5(){
		  var time = new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
		  
		  this.contipayMessage = `
			<div class="bubblecontipay bounceIn">
				<div class="txt">
					<p class="name">ContiPay</p>
					<p class="message">Settilement admin sub menu.</p>
					<p class="name">1. Change time preiod</p>
					<p class="name">2. Log Delay</p>
					<p class="name">0. Back to Menu</p>
					<span class="timestamp">${time}</span>
				</div>
				<div class="bubble-arrow"></div>
			</div>	
			`
			this.currentChat.push({time:Date.now(),bubble:this.contipayMessage})
			this.contipayMessage = ''
	  },
	  validateEmail(email) {
		    //console.log(email)
			var re = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
			return re.test(String(email).toLowerCase());
			
		},
	 async sendMessage(e){
		  if (e.keyCode === 13) {
				
				let msg = this.clientMessage
				let botResponse = ''
				let botThink = ''
				
				if(msg.length > 1){
					//User Admin
					if(msg == 1 && this.thoughtData == '0'){
						this.thoughtData += '1'
						setTimeout(() => this.menu1(), 1000);
						
					}	
						//Add User 
					    else if(msg == 1 && this.thoughtData == '01'){
							this.thoughtData += '1'
							setTimeout(() => this.menu11(), 1000);
							
						}
							//Get user details
							else if(msg != 0 && this.thoughtData == '011'){
								// console.log(this.clientMessage)
								// console.log(this.validateEmail(this.clientMessage))
								// console.log(msg)
								// console.log(this.validateEmail(msg))
								// if(this.validateEmail(msg)){
								// 	//console.log(msg === '@')
								// 	console.log(this.validateEmail(msg))
								// }
								// else{
								// 	//console.log(msg === '@')
								// 	console.log(this.validateEmail(msg))
								// }							
							}
							//Exit user detials form
							else if(msg == 0 && this.thoughtData == '011'){
							this.thoughtData = '01'
							setTimeout(() => this.menu1(), 1000);
							
						    }
						//Disable User 
					    else if(msg == 1 && this.thoughtData == '01'){
							this.thoughtData += '2'
							setTimeout(() => this.menu12(), 1000);
							
						}
						//Change User Role 
					    else if(msg == 1 && this.thoughtData == '01'){
							this.thoughtData += '3'
							setTimeout(() => this.menu13(), 1000);
							
						}
						//Change User Approval Level 
					    else if(msg == 1 && this.thoughtData == '01'){
							this.thoughtData += '4'
							setTimeout(() => this.menu14(), 1000);
							
						}
						//Back to main menu
					    else if(msg == 0 && this.thoughtData == '01'){
							this.thoughtData = '0'
							setTimeout(() => this.menu(), 1000);
							
						}
					//Centre Admin
					else if(msg == 2 && this.thoughtData == '0'){
						this.thoughtData += '2'
						setTimeout(() => this.menu2(), 1000);
						
					}
					//Company Admin
					else if(msg == 3 && this.thoughtData == '0'){
						this.thoughtData += '3'
						setTimeout(() => this.menu3(), 1000);
						
					}
					//Promotion Admin
					else if(msg == 4 && this.thoughtData == '0'){
						this.thoughtData += '4'
						setTimeout(() => this.menu4(), 1000);
						
					}
					//Settlement Admin
					else if(msg == 5 && this.thoughtData == '0'){
						this.thoughtData += '5'
						setTimeout(() => this.menu5(), 1000);
						
					}
					else if(msg == 6 && this.thoughtData == '0'){
						this.thoughtData += '6'
						setTimeout(() => this.exportChatToPdf(), 1000);
						
					}
					else if(msg == 1 && this.thoughtData == '06'){
						this.thoughtData += '1'
						setTimeout(() => this.exportChatToPdf(), 1000);
						
					}
					else if(msg == 2 && this.thoughtData == '06'){
						this.thoughtData = '0'
						setTimeout(() => this.menu(), 1000);
						
					}
					else if(msg == 0 && this.thoughtData != '0'){
						this.thoughtData = '0'
						setTimeout(() => this.menu(), 1000);
						
					}
					else if(msg === 'help'){
						this.thoughtData = '0'
						setTimeout(() => this.menu(), 1000);
						
					}
					else{
						await this.sendClientMessage(this.clientMessage)
						document.getElementById("texttype").value = "";
						//send message to the bot
						await this.axios.post(this.nodeApi+'sendMessageChatBot',{message:msg})
						.then(res =>{
							//console.log(res.data.support[0].value)
							botThink = res.data.support[0].value
						}).catch(err =>{

						})
						//get bot message from database
						await this.axios.post(this.nodeApi+'getResponseChatBot',{message:botThink})
						.then(res =>{
							//console.log(res.data.details)
							botResponse = res.data.details
						}).catch(err =>{

						})
						this.sendContiPayMessage(botResponse)
					}
					
					}

					
					this.contipayMessage = ''
					this.clientMessage = ''
			} 
	  },
  }
}


</script>
<style scoped>
 
.chat-box{
	position: fixed;
	right: 20px;
	bottom: 0px;
	background: url('wallpaper.fw.png');
	background-repeat: no-repeat;
	background-color: #bebcbc;
	background-size: cover;
	border:1px solid rgb(149, 156, 255);
	width: 315px;
	border-radius: 5px 5px 0px 0px;
}
.chat-head{
	/* width: inherit; */
	height: 45px;
	background: #2983dd;
	border-radius: 5px 5px 0px 0px;
}
.chat-head h4{
	color: white;
	padding: 8px;
	display: inline-block;
}
.chat-head img{
	cursor: pointer;
	float: right;
	width: 25px;
	margin: 10px;
}
.chat-body{
	height: 355px;
	width: inherit;
	overflow: auto;
	margin-bottom: 45px;
}
.chat-text{
	position: fixed;
	bottom: 0px;
	height: 45px;
	width: inherit;
}
.chat-text textarea{
	width: 314px;
	height: auto; 
	
	background-color: rgb(75, 126, 238);
    color: white;
	box-sizing: border-box;
	border: 1px solid #8ec1e2;
	padding: 10px;
	resize: none;
}
.chat-text textarea:active, .chat-text textarea:focus, .chat-text textarea:hover{
	border-color: royalblue;
}
.msg-send{
	background: #2ecc71;
}
.msg-receive{
	background: #3498db;
}
.msg-send, .msg-receive{
	width: 200px;
	/* height: 35px; */
	padding: 5px 5px 5px 10px;
	margin: 10px auto;
	border-radius: 3px;
	line-height: 30px;
	position: relative;
	color: white;
}
.msg-receive:before{
	content: '';
	width: 0px;
	height: 0px;
	position: absolute;
	border: 15px solid;
	border-color: transparent #3498db transparent transparent;
	left: -29px;
	top: 7px;
}
.msg-send:after{
	content: '';
	width: 0px;
	height: 0px;
	position: absolute;
	border: 15px solid;
	border-color: transparent transparent transparent #2ecc71;
	right: -29px;
	top: 7px;
}
.msg-receive:hover, .msg-send:hover{
	/* opacity: .9; */
}

.msg-insert{
	
}


</style>
