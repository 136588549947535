/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
<template>
    


 <div>
          <v-toolbar flat color="blue darken-4">
            <v-toolbar-title class="white--text">Merchant Administration</v-toolbar-title>
            <v-divider
              class="mx-2"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogMerchant" max-width="800px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">New Merchant</v-btn>
                
              </template>
             

               <v-stepper v-model="e1">
                    <v-stepper-header>
                      <v-stepper-step editable :complete="e1 > 1" step="1">Company Details</v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step editable :complete="e1 > 2" step="2">Company Contacts</v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step editable step="3">Remmitance Information</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <v-stepper-content step="1" >
                         <v-layout wrap>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.name" label="Company Name"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.reg_number" label="Reg No"></v-text-field>
                      </v-flex>
                       <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.vat_number" label="VAT No"></v-text-field>
                      </v-flex>
                       <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.telephone" label="Contacts"></v-text-field>
                      </v-flex>
                       <v-flex xs12 sm12 md12>
                        <v-text-field v-model="editedMerchant.address" label="Address"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.website" label="Website"></v-text-field>
                      </v-flex>
                       <v-flex xs12 sm6 md6>
                        <v-text-field v-model="editedMerchant.zip_code" label="Zip Code"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-combobox
                         
                          deletable-chips
                          item-text="name"
                          item-value="id"
                          :items="countries"
                          chips
                          label="Country"
                          @input="getStates"
                        ></v-combobox>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-combobox
                          
                          deletable-chips
                          item-text="name"
                          item-value="id"
                          :items="state"
                          chips
                          label="State"
                          @input="getCities"
                        ></v-combobox>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-combobox
                          
                          deletable-chips
                          item-text="name"
                          item-value="id"
                          :items="city"
                          chips
                          label="City"
                          @input="getCitiesID"
                        ></v-combobox>
                      </v-flex>
                      
                      
                    </v-layout>
                  

                        <v-btn
                          color="primary"
                          @click="e1 = 2"
                        >
                          Continue
                        </v-btn>

                       
                      </v-stepper-content>

                      <v-stepper-content step="2">
                       
                        <v-tabs right>
                            <v-tab>
                              <v-icon color="success" left>contacts</v-icon>
                              Primary
                            </v-tab>
                            <v-tab>
                              <v-icon color="primary" left>contact_mail</v-icon>
                              Billing
                            </v-tab>
                            <v-tab>
                              <v-icon color="orange" left>contact_phone</v-icon>
                              Support
                            </v-tab>

                            <v-tab-item>
                              <v-layout wrap>
                                <v-flex xs12 sm6 md6>
                                  <v-combobox
                                    v-model="merchantContactsPrim.title"
                                    deletable-chips
                                    item-text="name"
                                    item-value="name"
                                    :items="titles"
                                    chips
                                    label="Title"
                                  ></v-combobox>
                                </v-flex>
                                <v-flex xs12 sm6 md6 pa-2>
                                  <v-text-field v-model="merchantContactsPrim.name" label="Contact Name"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantContactsPrim.direct_line" label="Phone Office"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantContactsPrim.mobile_line" label="Phone Mobile"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                  <v-text-field v-model="merchantContactsPrim.email" label="Email"></v-text-field>
                                </v-flex>

                              </v-layout>
                            </v-tab-item>
                            <v-tab-item>
                               <v-layout wrap>
                                <v-flex xs12 sm6 md6 >
                                  <v-combobox
                                    v-model="merchantContactsBill.title"
                                    deletable-chips
                                    item-text="name"
                                    item-value="name"
                                    :items="titles"
                                    chips
                                    label="Title"
                                    
                                  ></v-combobox>
                                </v-flex>
                                <v-flex xs12 sm6 md6 pa-2>
                                  <v-text-field v-model="merchantContactsBill.name" label="Contact Name"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantContactsBill.direct_line" label="Phone Office"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantContactsBill.mobile_line" label="Phone Mobile"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                  <v-text-field v-model="merchantContactsBill.email" label="Email"></v-text-field>
                                </v-flex>

                              </v-layout>
                            </v-tab-item>
                            <v-tab-item>
                               <v-layout wrap>
                                <v-flex xs12 sm6 md6>
                                  <v-combobox
                                    v-model="merchantContactsSupp.title"
                                    deletable-chips
                                    item-text="name"
                                    item-value="name"
                                    :items="titles"
                                    chips
                                    label="Title"
                                  ></v-combobox>
                                </v-flex>
                                <v-flex xs12 sm6 md6 pa-2>
                                  <v-text-field v-model="merchantContactsSupp.name" label="Contact Name"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantContactsSupp.direct_line" label="Phone Office"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantContactsSupp.mobile_line" label="Phone Mobile"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                  <v-text-field v-model="merchantContactsSupp.email" label="Email"></v-text-field>
                                </v-flex>

                              </v-layout>
                            </v-tab-item>
                          </v-tabs>
                       
                        <v-btn
                          color="primary"
                          @click="e1 = 3"
                        >
                          Continue
                        </v-btn>

                        <v-btn text @click="e1 = 1">back</v-btn>
                      </v-stepper-content>

                      <v-stepper-content step="3">
                        
                          <v-tabs right>
                            <v-tab>
                              <v-icon color="success" left>smartphone</v-icon>
                              EcoCash
                            </v-tab>
                            <v-tab>
                              <v-icon color="primary" left>domain</v-icon>
                              Banking
                            </v-tab>
                           

                            <v-tab-item>
                              <v-layout wrap>
                                
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantRemmitMobile.phone" label="Registered Number"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantRemmitMobile.company_name" label="Ecocash Name"></v-text-field>
                                </v-flex>
                                

                              </v-layout>
                            </v-tab-item>
                            <v-tab-item>
                               <v-layout wrap>
                                
                                <v-flex xs12 sm12 md12>
                                  <v-text-field v-model="merchantRemmitBank.bank_name" label="Bank Name"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantRemmitBank.account_number" label="Account Number"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6>
                                  <v-text-field v-model="merchantRemmitBank.account_name" label="Account Name"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantRemmitBank.branch_name" label="Branch Name"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="merchantRemmitBank.sort_code" label="Sort Code"></v-text-field>
                                </v-flex>

                              </v-layout>
                            </v-tab-item>
                            
                          </v-tabs>
                       


                        <v-btn
                        v-show="!editCheck"
                          color="primary"
                          @click="saveNewMerchant"
                        >
                          Save
                        </v-btn>

                        <v-btn
                          v-show="editCheck"
                          color="primary"
                          @click="saveMerchant"
                        >
                          Update
                        </v-btn>
                        

                        <v-btn text @click="e1 = 2">back</v-btn>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>


                
               
               
                
                
            </v-dialog>
          </v-toolbar>
           <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="headersMerchant"
            :items="MerchantData"
            class="elevation-1"
            :search="search"
            width="10"
          >
            <template v-slot:items="props">

            
             <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td class="text-xs-left">{{ props.item.reg_number }}</td>
      
              <td class="text-xs-left">{{ props.item.address }}</td>
              <td class="text-xs-left">{{ props.item.telephone }}</td>

      
              <td class="justify-left">
               
               <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  v-on="on"
                  @click="editItemMerchant(props.item)"
                >
                  edit
                </v-icon>
                 </template>
                    <span>Edit Merchant</span>
                  </v-tooltip>
                          
               <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                   v-on="on"
                  @click="deleteItemMerchant(props.item)"
                >
                  delete
                </v-icon>
                 </template>
                    <span>Delete Merchant</span>
                  </v-tooltip>

            <v-menu>
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  small                                    
                   v-on="{ ...tooltip, ...menu }"
                >
                  account_balance
                </v-icon>
                </template>
                    <span>Merchant Add-Ons</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-tile
                    v-for="(item, index) in menuitems"
                    :key="index"
                    @click="selectedMenuItem(props.item.id,item.title)"
                  >
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
              
              </td>

               
            </template>
             <template v-slot:no-data>
              <h4>No records found</h4>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
              </v-alert>
            </template>
          </v-data-table>

<!-- dialog for new centre -->
    <v-dialog v-model="newCentredialog" persistent max-width="500px">

      <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-card-title class="blue title font-weight-regular justify-space-between">
      <span>{{ currentTitle }}</span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="step"
      ></v-avatar>
    </v-card-title>

    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card-text>
         <v-layout wrap>
              
              <v-flex xs12>
                <v-text-field v-model="centres.name" label="Enter Centre Name" required></v-text-field>
              </v-flex>
              
              <v-flex xs12>
               <v-combobox
                            v-model="currenciesSelected"
                              item-text="iso_code"
                              item-value="id"
                                                      
                              :items="centreCurrencies"
                              multiple
                              chips
                              deletable-chips
                              label="Allocate Centre Currencies"
                             
                              persistent-hint
                            >
                            </v-combobox>
               
              </v-flex>
              <v-flex xs12>
                <input label="Select Centre Logo" type="file" ref="file" @change="uploaderFileCheck" required>
              </v-flex>
            </v-layout>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
        <v-card-text>
          <v-layout wrap>
              
              <v-flex xs12>
                <v-text-field v-model="remittance.maturity_hours" type="number" label="Enter Maturity Hours" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="remittance.auto_remit_hours" type="number" label="Enter Auto Remit Hours" required></v-text-field>
              </v-flex>
              
              
            </v-layout>
        </v-card-text>
      </v-window-item>
    </v-window>
      
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="step === 1"
        text
        @click="step--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
       v-show="step < 2"
        color="primary"
        depressed
        @click="step++"
      >
        Next
      </v-btn>
      <v-btn
        v-show="step === 2"
        color="primary"
        depressed
        @click="saveCentre"
      >
        Save
      </v-btn>
       <v-btn
        class="white--text"
        color="red"
        depressed
        @click="newCentredialog = false"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-dialog>


<!-- dialog for new VSA centre -->
    <v-dialog v-model="newVASCentredialog" persistent max-width="600px">
      
      <v-card>
        <v-card-title>
          <span class="headline">Create Loyalty Campaign</span>
        </v-card-title>
        <v-card-text>
           <v-layout wrap>
              
              <v-flex xs12>
                <v-text-field v-model="campaignName" label="Enter Loyalty Campaign Name" required></v-text-field>
              </v-flex>
             <v-flex xs12>
               <v-combobox
                             v-model="selectedCentresVAS"
                              item-text="centre_name"
                              item-value="id"
                                                      
                              :items="centreArray"
                              multiple
                              chips
                              deletable-chips
                              label="Select Centre To Link Campaign To"
                             
                              persistent-hint
                            >
                            </v-combobox>
               
              </v-flex>
              <v-flex xs12>
               <v-combobox
                            v-model="vasCurrency"
                              item-text="iso_code"
                              item-value="id"
                                                      
                              :items="vasCurrency"
                              chips
                              deletable-chips
                              label="Choose Default Currency"
                             
                              persistent-hint
                            >
                            </v-combobox>
               
              </v-flex>
              <v-flex xs12>
              
            </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newVASCentredialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="newVASCentre">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
<!-- dialog for user link to vas -->
    <v-dialog v-model="userLinkVASdialog" persistent max-width="600px">
      
      <v-card>
        <v-card-title>
          <span class="headline">User Loyalty Intergration</span>
        </v-card-title>
        <v-card-text>
        <v-layout wrap>
              
              
             <v-flex xs12>
               <v-combobox
                             
                              item-text="NAME"
                              item-value="id"
                                                      
                              :items="selectedCampaignArray"
                             
                              chips
                              deletable-chips
                              label="Select campaign to add users"
                              
                              persistent-hint
                            >
              </v-combobox>
               
              </v-flex>
                
             <v-flex xs12>
               <v-combobox
                             
                              item-text="name"
                              item-value="id"
                                                      
                              :items="selectedUsersArray"
                              chips
                              deletable-chips
                              label="Select users to link to the above campaign to"
                              
                              persistent-hint
                            >
                </v-combobox>
               
              </v-flex>
            
              
            </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="userLinkVASdialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="userLinkVASdialog = false">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

        </div>
    </template>
    <script>
    /* eslint-disable no-unused-vars */
    /* eslint-disable no-undef */
    export default {
      beforeMount() {
        this.initializeMerchant()
      },
      watch: {
        currenciesSelected(val){
          
          let currencies = ''
          let id = 0
          let iso_code = ''
          let data = ''
          
         for(let i = 0;i < val.length;i++){ 
           id = val[i].id
           iso_code = val[i].iso_code
          //console.log(val.length)
          if(i < val.length-1){
            //console.log(i)
            data += '"'+id+'"'+':'+'"'+iso_code+'"'+','
          }else{
            //console.log(i)
            data += '"'+id+'"'+':'+'"'+iso_code+'"'
          }
                   
        //   
         }
        
          currencies = '{'+data+'}'
          this.centres.currencies = currencies
        },
        dialogMerchant (val) {
          val || this.closeMerchant()
        },
        
        selectedCentresVAS(val){
          //console.log(val)
        }
      },
        data() {
            return {
                 nodeApi: 'https://serv.contitouch.co.zw/',
                // nodeApi : 'http://localhost:81/',
                MerchantData: [],
                countries: [],
                step: 1,
               // selectedCentres: [],
                selectedCentresVAS: [],
                campaignName: '',
                selectedUserId : 0,
                selectedCentresid: 0,
                currenciesSelected : [],
                vasCurrency:270,
                currencyJSOn : '',
                selectedCampaignid:0,
                selectedCampaignArray: [],
                selectedUsersArray: [],
                state:[],
                centreArray: [],
                centres: {
                  name: '',
                  currencies: '',
                  logoUrl: '',
                  company_id:0
                },
                remittance: {
                  maturity_hours:0,
                  auto_remit_hours:0
                },
                centreCurrencies: [],
                files: '',
                newCentredialog: false,
                userLinkVASdialog: false,
                newVASCentredialog : false,
                city: [],
                titles: ['Dr','Miss','Mr','Mrs','Prof'],
                 e1: 0,
                editCheck: false,
                dialogMerchant: false,
                 
                searchType: '',
                selectedCompanies: [],
                company_id: 0,
                search: '',
                 menuitems: [
                        { title: 'Create Centre' },
                        { title: 'Create Loyalty Campaign' }
                      ],
                headersMerchant: [
                  {
                    text: 'ID',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                    },
                    {
                    text: 'Name',
                    align: 'left',
                    sortable: false,
                    value: 'name'
                    },
                    { text: 'Registration No', value: 'reg_number' },
                    
                    { text: 'Address', value: 'address', width: "40%" },
                    { text: 'Contacts', value: 'telephone' },
                    { text: 'Actions', value: 'name', sortable: false }
                ],
                merchantRemmitMobile: {
                  company_id : '',
                  company_name: '',
                  phone:'',
                  provider_id: 1
                },
                merchantRemmitBank: {
                  company_id : '',
                  bank_name: '',
                  account_number: '',
                  account_name: '',
                  sort_code: '',
                  branch_name:'',
                  provider_id: 1
                },
                merchantContactsPrim: {
                  company_id: '',
                  type_id : 1,
                  name: '',
                  title:'',
                  direct_line: '',
                  mobile_line: '',
                  email : ''
                },
                merchantContactsSupp: {
                  company_id: '',
                  type_id : 3,
                  name: '',
                  title:'',
                  direct_line: '',
                  mobile_line: '',
                  email : ''
                },
                merchantContactsBill: {
                  company_id: '',
                  type_id : 2,
                  title:'',
                  name: '',
                  direct_line: '',
                  mobile_line: '',
                  email : ''
                },
                editedIndexMerchant: -1,
                editedMerchant: {
                  name: '',
                  reg_number: '',
                  address: '',
                  telephone: '',
                  vat_number: '',
                  website: '',
                  zip_code: '',
                  country_id:'',
                  state_id: '',
                  city_id: '',
                  date_updated: Date.now(),
                  date_created: Date.now(),
                  created_by: ''
                },
                defaultMerchant: {
                  name: '',
                  reg_number: '',
                  address: '',
                  telephone: '',
                  vat_number: '',
                  website: '',
                  zip_code: '',
                  country_id:'',
                  state_id: '',
                  city: '',
                  date_created: '',
                  created_by: ''
                }
                
            }
        },
        computed: {
        currentTitle () {
          switch (this.step) {
            case 1: return 'Centre Detials'
            case 2: return 'Remittance Configuration'
            default: return 'Centre Detials'
          }
        }
        },
        methods: {
          newVASCentre(){
          
            let detailsData = {
              company_id: this.company_id,
              name: this.campaignName,
              currency: this.vasCurrency
            }

            this.axios.post(this.nodeApi+'addVASCentre',{details:detailsData,centres:this.selectedCentresVAS})
            .then(res =>{
              //console.log(res.data)
               if(res.data.affectedRows){
                this.newVASCentredialog = false
                
                 Swal.fire(
                            'Loyalty Centre Created!',
                            'Your loyalty centre has been created.',
                            'success'
                          )
              }else{
                  Swal.fire({
                        type: "error",
                        title: "Can not add centres!!",
                        text: res.data.sqlMessage
                    });
              }
              
              

            }).catch(err =>{
              Swal.fire({
                        type: "error",
                        title: "Can not add centre!!",
                        text: err
                    });
            })


            
            

          },
          uploaderFileCheck(e){
            
            this.files = this.$refs.file.files[0]
           // console.log(e)
           // console.log(this.files)
          },
        async saveCentre(){
         
            this.centres.company_id = this.company_id
            let this_ = this
            
            

            if(this.files != ''){
              let formData = new FormData();
                formData.append('file', this.files);
                formData.append('companyId', this.centres.company_id);
      
            await this.axios.post('/service/service?endPoint=request/service/uploadCentreLogo',
                    formData,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorisation': 'SzJObE1sWlFUVEp0UkVremN6ZFplRUprUlVReFp6MDlCVlJ2eWY4VzE1NTY4OTI4MjQ=',
                        'timestamp':'1556893117'
                    }
                  }
                ).then(res =>{
                  if(!res.data.fileName){
                    Swal.fire({
                        type: "error",
                        title: "Can not upload Image!!",
                        text: res.data.message
                    });
                  }else if(res.data.fileName){
                    //console.log(res.data)
                    this.centres.logoUrl = res.data.fileName
                  }
                

                     
                })
                .catch(function(err){
                   Swal.fire({
                        type: "error",
                        title: "Can not upload Image!!",
                        text: err
                    });
                
                });

           this.axios.post(this.nodeApi+'addMerchantCentre',{centres:this.centres,remittances:this.remittance})
            .then(res =>{
               if(res.data.affectedRows){
                this.newCentredialog = false
                 this.files = ''
                 Swal.fire(
                            'Centre Created!',
                            'Your Centre has been created.',
                            'success'
                          )
              }else{
                  Swal.fire({
                        type: "error",
                        title: "Can not add centres!!",
                        text: res.data.sqlMessage
                    });
              }
              
              

            }).catch(err =>{
              Swal.fire({
                        type: "error",
                        title: "Can not add centre!!",
                        text: err
                    });
            })

            }
           

          },
          selectedUserIdFun(id){
            this.selectedUserId = id
          },
          selectedCampaignidFun(id){
            this.selectedCampaignId = id
          },
          selectedCentreIdFun(id){
            this.selectedCentreId = id
          },
          selectedMenuItem(id,menu){
            
            this.company_id = id
            if(menu == 'Create Centre'){
                this.step = 1
                this.centreCurrencies = []
                this.axios.get(this.nodeApi+'getCurrencies2')
                .then(res =>{
                  //console.log(res.data)
                  res.data.forEach(element => {
                      this.centreCurrencies.push(element)
                  });
                  
                }).catch(err =>{

                })
              this.newCentredialog = true
               
            }
            if(menu == 'Create Loyalty Campaign'){
              this.centreArray = []
                this.axios.post(this.nodeApi+'getCentresByCompanyID',{element1:id})
                .then(res =>{
                  
                  res.data.forEach(element => {
                      this.centreArray.push(element)
                  });
                  
                }).catch(err =>{

                })
                this.newVASCentredialog  = true
            }
            if(menu == 'Link User to Campaign'){
              this.selectedCampaignArray = []
               this.axios.post(this.nodeApi+'getVASCentresByCompanyID',{element1:id})
                .then(res =>{
                  
                  res.data.forEach(element => {
                      this.selectedCampaignArray.push(element)
                  });
                  
                }).catch(err =>{

                })

                this.axios.get(this.nodeApi+'getUsersVAS')
                .then(res =>{
                  
                  res.data.forEach(element => {
                      this.selectedUsersArray.push(element)
                  });
                  
                }).catch(err =>{

                })
              
                this.userLinkVASdialog = true
                
            }
            
          },
        async getStates(data){
          this.editedMerchant.country_id = data.id
          if(data.id){
            await this.axios.post(this.nodeApi+'getStates',{country_id:data.id}).then(data =>{
                this.state = data.data
                
                }).catch(err =>{
                Swal.fire({
                        type: "error",
                        title: "Can not load reports!!",
                        text: err
                    });
                })
          }
            
          },
        async getCities(data){
         this.editedMerchant.state_id = data.id
          if(data.id){
          await this.axios.post(this.nodeApi+'getCities',{state_id:data.id}).then(data =>{
                this.city = data.data
                
                }).catch(err =>{
                Swal.fire({
                        type: "error",
                        title: "Can not load reports!!",
                        text: err
                    });
                })
            }

          },
         getCitiesID(data){
         this.editedMerchant.city_id = data.id
          

          },
          saveNewMerchant(){
            Swal.fire({
              type: "success",
              title: "Merchant adding processing",
              text: "Saving....",
              onBeforeOpen: () => {
                
              Swal.showLoading()
              }
               
            });
       
            this.axios.post(this.nodeApi+'addMerchant',{company:this.editedMerchant,contactsP:this.merchantContactsPrim,contactsB:this.merchantContactsBill,contactsS:this.merchantContactsSupp,banking:this.merchantRemmitBank,mobile:this.merchantRemmitMobile})
            .then(res =>{
              if(res.data.affectedRows){
                this.dialogMerchant = false
                 Swal.fire(
                            'Merchant Created!',
                            'Your Merchant has been created.',
                            'success'
                          )
              }else{
                  Swal.fire({
                        type: "error",
                        title: "Can not add centres!!",
                        text: res.data.sqlMessage
                    });
              }
              
            }).catch(err =>{
              // console.log(res.data)
            })
          },
             //edit Merchant Data
      editItemMerchant (item) {
        this.editedIndexMerchant = this.MerchantData.indexOf(item)
        this.editedMerchant = Object.assign({}, item)
        this.dialogMerchant = true
        this.editCheck = true
      },
      //Delete Merchant Data
      deleteItemMerchant (item) {
        const index = this.MerchantData.indexOf(item)
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.MerchantData.splice(index, 1)
          }
        })
       
      },


              closeMerchant () {
                this.editCheck = false
                this.dialogMerchant = false
                setTimeout(() => {
                this.editedMerchant = Object.assign({}, this.defaultMerchant)
                this.editedIndexMerchant = -1
                }, 300)
            },

            saveMerchant () {
                if (this.editedIndexMerchant > -1) {
                Object.assign(this.MerchantData[this.editedIndexMerchant], this.editedMerchant)
                } else {
                this.MerchantData.push(this.editedMerchant)
                }
                this.closeMerchant()
            },
            async initializeMerchant () {
              this.editedMerchant.created_by = this.$session.get('user').id
                //get All Merchants from the Database through the getCompanies API
              await this.axios.get(this.nodeApi+'getCompanies').then(data =>{
                this.MerchantData = data.data
                
                }).catch(err =>{
                Swal.fire({
                        type: "error",
                        title: "Can not load reports!!",
                        text: err
                    });
                })

              await this.axios.get(this.nodeApi+'getCountries').then(data =>{
                this.countries = data.data
                
                }).catch(err =>{
                Swal.fire({
                        type: "error",
                        title: "Can not load reports!!",
                        text: err
                    });
                })



                
            }
        },
    }
    </script>
    <style scoped>
    
    </style>
    
    